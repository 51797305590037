var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-card',{staticClass:"ma-2",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"text-center",staticStyle:{"background-color":"#f37928"}},[_c('v-row',[_c('v-col',{staticClass:"white--text"},[_vm._v(" Less Effective ")])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'no-method',
                      _vm.activeMethodIndex == 0 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(0)}}})])],1),_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'male-condom',
                      _vm.activeMethodIndex == 1 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(1)}}})])],1),_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'female-condom',
                      _vm.activeMethodIndex == 2 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(2)}}})])],1)],1)],1),_c('v-col',{staticClass:"text-center",staticStyle:{"background-color":"#006477"}},[_c('v-row',[_c('v-col',{staticClass:"white--text"},[_vm._v(" Effective ")])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'pill',
                      _vm.activeMethodIndex == 3 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(3)}}})])],1),_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'shot',
                      _vm.activeMethodIndex == 4 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(4)}}})])],1)],1)],1),_c('v-col',{staticClass:"text-center",staticStyle:{"background-color":"#669900"}},[_c('v-row',[_c('v-col',{staticClass:"white--text"},[_vm._v(" More Effective ")])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'implant',
                      _vm.activeMethodIndex == 5 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(5)}}})])],1),_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'copper-iud',
                      _vm.activeMethodIndex == 6 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(6)}}})])],1),_c('v-col',[_c('v-card',{staticClass:"transparent pt-2",attrs:{"flat":""}},[_c('div',{class:[
                      'method_radio',
                      'sterilization',
                      _vm.activeMethodIndex == 7 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(7)}}})])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h3',{staticStyle:{"color":"#006477"}},[_vm._v(" "+_vm._s(_vm.activeMethod.text)+" ")])])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},_vm._l((100),function(n){return _c('v-col',{key:n,staticClass:"text-center",attrs:{"lg":"1","md":"2","sm":"2","offset-lg":n % 10 == 1 ? 1 : 0,"no-gutters":""}},[_c('div',{class:['egg', n <= _vm.activeMethod.chance ? 'sperm' : '']})])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }