<template>
  <v-card color="grey darken-4" class="mb-1 flex-grow-1">
    <v-img :aspect-ratio="16 / 9" :src="require(`../assets/${image}`)"> </v-img>
    <v-card-text class="text-lg-caption white--text">
      {{ heading }}
    </v-card-text>
    <v-card-actions class="align-bottom">
      <v-btn text color="teal accent-4" @click="reveal = true"> Learn More </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal scroll" height="35vh">
        <v-card-text>
          <p class="text-caption grey--text" v-html="text"></p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn text color="teal accent-4" @click="reveal = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'HoverCard',
  props: {
    heading: String,
    text: String,
    image: String,
  },
  data: () => ({
    reveal: false,
  }),
  methods: {
    // getImgUrl() {
    //   return require(this.image);
    // },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
