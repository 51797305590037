<template>
  <v-card color="mb-1">
    <v-img height="250" :src="require(`../assets/${image}`)" aspect-ratio="16/9"> </v-img>
    <v-card-title class="text-h6 orange--text">
      {{ heading }}
    </v-card-title>
    <v-list shaped disabled>
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="teal" large>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ point1 }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="purple" large>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ point2 }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="green" large>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ point3 }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'TextOverlayCard',
  props: {
    heading: String,
    point1: String,
    point2: String,
    point3: String,
    point4: String,
    image: String,
  },
  data: () => ({
    reveal: false,
  }),
  methods: {
    // getImgUrl() {
    //   return require(this.image);
    // },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
