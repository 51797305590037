var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center",staticStyle:{"background-color":"#fff"}},[_c('v-row',{staticClass:"morelessrow",attrs:{"justify":"space-between"}},[_c('v-col',[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_vm._v(" << More Effort")])],1),_c('v-col',[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_vm._v(" Less Effort >>")])],1)],1),_c('v-row',{staticClass:"morelessrowlowres mb-1"},[_c('v-col',[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_vm._v("More Effort")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"wrapper",attrs:{"cols":"12","sm":"12","lg":"12","md":"12"}},[_c('div',{class:[
                'ma-1',
                'method_radio',
                'male-condom',
                _vm.activeMethodIndex == 0 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(0)}}}),_c('div',{class:[
                'ma-1',
                'method_radio',
                'female-condom',
                _vm.activeMethodIndex == 1 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(1)}}}),_c('div',{class:[
                'ma-1',
                'method_radio',
                'pill',
                _vm.activeMethodIndex == 2 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(2)}}}),_c('div',{class:[
                'ma-1',
                'method_radio',
                'shot',
                _vm.activeMethodIndex == 3 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(3)}}}),_c('div',{class:[
                'ma-1',
                'method_radio',
                'implant',
                _vm.activeMethodIndex == 4 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(4)}}}),_c('div',{class:[
                'ma-1',
                'method_radio',
                'copper-iud',
                _vm.activeMethodIndex == 5 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(5)}}}),_c('div',{class:[
                'ma-1',
                'method_radio',
                'sterilization',
                _vm.activeMethodIndex == 6 ? 'selected_method' : '' ],on:{"click":function($event){return _vm.setActive(6)}}})])],1),_c('v-row',{staticClass:"morelessrowlowres mt-1"},[_c('v-col',[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","tile":""}},[_vm._v(" Less Effort")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h3',{staticStyle:{"color":"#006477"}},[_vm._v(" "+_vm._s(_vm.activeMethod.text)+" ")])])],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[(_vm.activeMethodIndex === 0)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-female-condom.png')}}):_vm._e(),(_vm.activeMethodIndex === 1)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-male-condom.png')}}):_vm._e(),(_vm.activeMethodIndex === 2)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-pill.png')}}):_vm._e(),(_vm.activeMethodIndex === 3)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-shot.png')}}):_vm._e(),(_vm.activeMethodIndex === 4)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-implant.png')}}):_vm._e(),(_vm.activeMethodIndex === 5)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-copper-iud.png')}}):_vm._e(),(_vm.activeMethodIndex === 6)?_c('v-img',{attrs:{"contain":"","src":require('../assets/app4/cal-sterilization.png')}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }