<template>
  <v-container fill-height fluid class="methodchoice">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex flex-wrap align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'methodchoice-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h6">
                How do I choose?
                <br />
                <br />
                We want to help you find the type of PrEP that works best for you, and that you’ll
                take.
              </v-card-title>
              <v-card-text>
                <v-row v-if="$vuetify.breakpoint.lgAndUp">
                  <v-col>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left text-xl-h4 text-lg-h5 green radiusTopLeft"
                              style="width: 30%"
                            ></th>
                            <th class="text-left text-xl-h4 text-lg-h5 green">
                              <span v-if="method1" class="white--text"
                                >{{ method1Heading }}
                                <v-icon large color="red darken-2" @click="method1 = null">
                                  mdi-close
                                </v-icon>
                              </span>
                              <v-dialog v-else v-model="method1dialog" persistent max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="primary mb-1" dark v-bind="attrs" v-on="on">
                                    Choose a Method to Compare >>
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title class="orange--text text-h6">
                                    Method 1 to Compare
                                  </v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col
                                        v-for="(imgCard, index) in methodChoiceCards"
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                        :key="index"
                                      >
                                        <v-card
                                          class="mb-1"
                                          @click="
                                            method1 = index + 1;
                                            method1dialog = false;
                                          "
                                        >
                                          <v-img
                                            height="100"
                                            :src="require(`../assets/${imgCard.image}`)"
                                            aspect-ratio="16/9"
                                          >
                                          </v-img>
                                          <v-card-text class="text-caption black--text text-center">
                                            <div v-html="imgCard.heading"></div>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </th>
                            <th class="text-left text-xl-h4 text-lg-h5 green radiusTopRight">
                              <span v-if="method2" class="white--text"
                                >{{ method2Heading }}
                                <v-icon large color="red darken-2" @click="method2 = null">
                                  mdi-close
                                </v-icon>
                              </span>
                              <v-dialog v-else v-model="method2dialog" persistent max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="primary mb-1" dark v-bind="attrs" v-on="on">
                                    Choose a Method to Compare >>
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title class="orange--text text-h6">
                                    Method 2 to Compare
                                  </v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col
                                        v-for="(imgCard, index) in methodChoiceCards"
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                        :key="index"
                                      >
                                        <v-card
                                          class="mb-1"
                                          @click="
                                            method2 = index + 1;
                                            method2dialog = false;
                                          "
                                        >
                                          <v-img
                                            height="100"
                                            :src="require(`../assets/${imgCard.image}`)"
                                            aspect-ratio="16/9"
                                          >
                                          </v-img>
                                          <v-card-text class="text-caption black--text text-center">
                                            <div v-html="imgCard.heading"></div>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(card, index) in cards" :key="index">
                            <td class="pa-2 primary--text text-caption">{{ card.cardTitle }}</td>
                            <td class="pa-2 text-caption">
                              <v-rating
                                v-if="method1 && card[`stars${method1}`]"
                                empty-icon="mdi-star-outline"
                                full-icon="mdi-star"
                                half-icon="mdi-half-full"
                                length="5"
                                size="15"
                                :value="card[`stars${method1}`]"
                                color="orange"
                                readonly
                              ></v-rating>
                              <div
                                class="pl-3"
                                v-if="method1"
                                v-html="card[`text${method1}`]"
                              ></div>
                            </td>
                            <td class="pa-2 text-caption">
                              <v-rating
                                v-if="method2 && card[`stars${method2}`]"
                                empty-icon="mdi-star-outline"
                                full-icon="mdi-star"
                                half-icon="mdi-half-full"
                                length="5"
                                size="15"
                                :value="card[`stars${method2}`]"
                                color="orange"
                                readonly
                              ></v-rating>
                              <div
                                class="pl-3"
                                v-if="method2"
                                v-html="card[`text${method2}`]"
                              ></div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.mdAndDown">
                  <v-col
                    lg="12"
                    md="12"
                    sm="12"
                    cols="12"
                    v-for="(card, index) in cards"
                    :key="index"
                  >
                    <v-card class="pa-2 success" dark>
                      <v-row>
                        <v-card-title class="yellow--text">{{ card.cardTitle }}</v-card-title>
                        <v-card-text>
                          <comparison-card
                            :heading1="card.heading1"
                            :heading2="card.heading2"
                            :heading3="card.heading3"
                            :text1="card.text1"
                            :text2="card.text2"
                            :text3="card.text3"
                            :stars1="card.stars1"
                            :stars2="card.stars2"
                            :stars3="card.stars3"
                          />
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ComparisonCard from '../components/ComparisonCard.vue';
import Rainbow from '../components/Rainbow.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MethodChoice',
  components: {
    ComparisonCard,
    Rainbow,
  },
  data: () => ({
    methodChoiceCards: [
      {
        heading: 'PrEP pill',
        image: 'prep.jpg',
      },
      {
        heading: 'PrEP ring',
        image: 'ring_coming_soon.jpg',
      },
      {
        heading: 'PrEP injection',
        image: 'injection_coming_soon.jpg',
      },
    ],
    method1: null,
    method1dialog: false,
    method2: null,
    method2dialog: false,
    model: null,
    methods: [
      {
        title: 'PrEP Pill',
        'How do I take it?': 'Take a pill every day by mouth',
        'How well does it work?':
          "Very well, if you take it every day. It works less well if you don't take it consistently",
        'Main side effects?':
          'A few peope get mild headache or nausea for a month, then those go away. Most people have no side effects',
        'Will my partner or family know?':
          'You will need to keep your pills in a place where you can take them every day. You can change the pill bottle, as some people may recognize the medicine.',
        'Is it safe for pregnancy or breastfeeding?':
          'Yes, it is safe for pregnancy or breastfeeding.',
      },
    ],
    cards: [
      {
        cardTitle: 'How do I take it?',
        heading1: 'PrEP Pill',
        heading2: 'PrEP Ring',
        heading3: 'PrEP Injection',
        text1: 'Take a pill every day by mouth',
        text2: 'Place a ring in your vagina every month',
        text3: 'Get and injection in your arm ever 2 months',
      },
      {
        cardTitle: 'How well does it work?',
        heading1: 'PrEP Pill',
        heading2: 'PrEP Ring',
        heading3: 'PrEP Injection',
        text1:
          "Very well, if you take it every day. It works less well if you don't take it consistently",
        text2:
          'Very well, if you leave it in place. It works less well if you take it out frequently',
        text3: 'Very well, if you come in for your injections on time.',
        stars1: 2,
        stars2: 1,
        stars3: 3,
      },
      {
        cardTitle: 'Main side effects?',
        heading1: 'PrEP Pill',
        heading2: 'PrEP Ring',
        heading3: 'PrEP Injection',
        text1:
          'A few people get mild headache or nausea for a month, then those go away. Most people have no side effects',
        text2: 'Some woman have some vaginal symptoms or itching.',
        text3: 'The PrEP injection may be a little uncomfortable for a day or two',
        stars1: 1,
        stars2: 1,
        stars3: 1,
      },
      {
        cardTitle: 'Will my partner or family know?',
        heading1: 'PrEP Pill',
        heading2: 'PrEP Ring',
        heading3: 'PrEP Injection',
        text1:
          'You will need to keep your pills in a place where you can take them every day. You can change the pill bottle, as some people may recognize the medicine.',
        text2:
          "Most partners do not feel the vaginal ring, even during sex. You will need to keep the next month's ring in a place where you can get it when it is time to change the ring. ",
        text3:
          'You have to get the injection from a healthcare provider, and you may have a small bruise on your arm, but otherwise injectable PrEP is completely private. ',
        stars1: 2,
        stars2: 2,
        stars3: 3,
      },
      {
        cardTitle: 'Is it safe for pregnancy or breastfeeding?',
        heading1: 'PrEP Pill',
        heading2: 'PrEP Ring',
        heading3: 'PrEP Injection',
        text1: 'Yes, it is safe for pregnancy or breastfeeding.',
        text2:
          'The PrEP ring is very likely safe in pregnancy and breastfeeding, and we will learn more from studies soon.',
        text3:
          'Little is known about the PrEP injection in pregnancy and breastfeeding. We will learn more from studies soon.',
        stars1: 3,
        stars2: 2,
        stars3: 1,
      },
      {
        cardTitle: 'Will it prevent HIV during all kinds of sex? What about injection drugs?',
        heading1: 'PrEP Pill',
        heading2: 'PrEP Ring',
        heading3: 'PrEP Injection',
        text1:
          'The PrEP pill prevents HIV during all types of sex (vaginal or anal) and injection drug use.',
        text2: 'The PrEP ring prevents HIV only during vaginal sex.',
        text3: 'The PrEP injection prevents HIV during vaginal and anal sex.',
        stars1: 3,
        stars2: 1,
        stars3: 3,
      },
    ],
  }),
  computed: {
    ...mapGetters(['skipfamilyplanning']),
    method1Heading() {
      if (this.method1) {
        return this.cards[this.method1][`heading${this.method1}`];
      } else return '';
    },
    method2Heading() {
      if (this.method2) {
        return this.cards[this.method2][`heading${this.method2}`];
      } else return '';
    },
  },
  methods: {
    navigateNext() {
      if (this.skipfamilyplanning) {
        return this.$router.push('/Finish');
      }
      return this.$router.push('/LearnMore'); // go to /LearnMore to route to family planning....../Finish to shorcut family planning
    },
    navigatePrev() {
      this.$router.push('/LetsTalkAboutPrepMenu');
    },
  },
};
</script>

<style lang="scss" scoped>
.methodchoice {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}

th.radiusTopLeft {
  border-top-left-radius: 20px;
}

th.radiusTopRight {
  border-top-right-radius: 20px;
}

table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
</style>
