<template>
  <v-container fill-height fluid class="letsgetstarted">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card ',
            $vuetify.breakpoint.lgAndUp ? 'letsgetstarted-card-background' : '',
          ]"
          elevation="20"
          style="min-height: 80vh"
        >
          <v-row>
            <v-col lg="6" offset-lg="5" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                LET'S GET STARTED
              </v-card-title>
              <v-card-text class="white--text">
                Today, we’ll talk first about HIV prevention and some exciting new methods that are
                available.
                <br />
                <br />
                Then, we’ll show you your options for family planning if you want to prevent
                pregnancy.
                <br />
                <br />
                If you have questions as you go, make sure to write them down to ask your provider!
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'Home',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/WhatAreMyOptions');
    },
    navigatePrev() {
      this.$router.push('/ReasonsWhy');
    },
  },
};
</script>

<style lang="scss" scoped>
.letsgetstarted {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
.letsgetstarted-card-background {
  background-image: url('../assets/consultation.png');
  background-position: 10 center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
