<template>
  <v-container fill-height fluid class="iudinfo">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'iudinfo-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                INFO QUICKIE: THE IUDC
              </v-card-title>
              <v-card-text class="white--text">
                <v-row>
                  <v-col lg="3" md="6" sm="12" cols="12">
                    <v-card-text class="white--text">
                      <div class="text-lg-h5 text-sm-h6">
                        We know some women don’t know much about the IUDC, so we wanted to let you
                        know it's one of the most effective forms of family planning you can get.
                      </div>
                      <br />
                      <ul>
                        <li>
                          The IUDC decreases your risk of pregnancy 20 times more than the pill.
                        </li>
                        <li>
                          It is a safe, private method of family planning that lasts up to 12 years.
                        </li>
                        <li>
                          Women of all ages can use the IUDC, including teens and young women.
                        </li>
                      </ul>
                    </v-card-text>
                  </v-col>
                  <v-col lg="3" md="6" sm="12" cols="12">
                    <v-card-text class="green--text">
                      <div class="text-lg-h5 text-sm-h6">
                        There are also a lot of IUDC myths out there, so here are some facts.
                      </div>
                      <br />
                      <ul>
                        <li>IUDCs are safe to use, even if you've never given birth.</li>
                        <li>They don't cause infection.</li>
                        <li>They won't keep you from getting pregnant in the future.</li>
                        <li>
                          Having it put into your uterus and taking it out are small procedures done
                          in your doctor's office. And once it's in, you don't have to think about
                          it for years or until you want to have it taken out.
                        </li>
                      </ul>
                    </v-card-text>
                  </v-col>
                  <v-col lg="3" md="6" sm="12" cols="12">
                    <v-card-text class="white--text">
                      <div class="text-lg-h5 text-sm-h6">
                        Sounds pretty convenient, right? It is.
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col lg="3" md="3" sm="12" cols="12">
                    <v-img max-height="500px" contain src="../assets/iud-woman.png"
                  /></v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'IUDInfo',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/how_to_women');
    },
    navigatePrev() {
      this.$router.push('/effectiveness');
    },
  },
};
</script>

<style lang="scss" scoped>
.iudinfo {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
