<template>
  <v-app>
    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({}),
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
html, body, v-main
  height: 100vh
  margin: 0
  padding: 0

// @media #{map-get($display-breakpoints, 'lg-and-up')}
//   .v-card
//     justify-self: center
//     max-width: 50%!important

.v-card__subtitle, .v-card__text
  font-size: 1.25rem!important
  font-weight: 300!important
  line-height: 1.775rem!important
  letter-spacing: 0.071428571em!important
  color: #fff!important
  z-index: 10!important

[type="next"]
  color: green!important

[type="previous"]
  color: #ff8c00!important

.colored-card
  background-color: #23395d !important
  border: 2px solid #fff


.v-card__text, .v-card__title
  word-break: normal!important

.rainbow
  height: 25px
  width: 100%

.rainbow
  height: 30px
  width: auto
  padding: 0px!important
  margin-top: -10px!important
  margin-left: -9px!important
  margin-right: -9px!important

.rainbow li
  display: inline-block
  height: 25px
  width: 20%

.rainbow li.dark_gray_blue
  background: #004d5d

.rainbow li.light_blue
  background: #83cfdd

.rainbow li.light_green
  background: #a2c800

.rainbow li.dark_orange
  background: #f37928

.v-stepper__step__step .v-icon.v-icon
  font-size: 0.8rem!important

.v-divider
  display: block
  flex: 1 1 0px
  max-width: 100%
  height: 2px
  max-height: 0px
  border: solid
  border-width: thin 0 0 0
  transition: inherit
</style>
