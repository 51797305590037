<template>
  <v-row>
    <v-col cols="12" lg="12">
      <h3 class="orange--text">{{ heading1 }}</h3>
      <v-divider />
      <div v-if="stars1">
        <v-rating
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-half-full"
          length="5"
          size="15"
          :value="stars1"
          color="orange"
          readonly
        ></v-rating>
      </div>
      <p class="caption pl-3">{{ text1 }}</p>
    </v-col>
    <v-col cols="12" lg="12">
      <h3 class="orange--text">{{ heading2 }}</h3>
      <v-divider />
      <div v-if="stars2">
        <v-rating
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-half-full"
          length="5"
          size="15"
          :value="stars2"
          color="orange"
          readonly
        ></v-rating>
      </div>
      <p class="caption pl-3">{{ text2 }}</p></v-col
    >
    <v-col cols="12" lg="12">
      <h3 class="orange--text">{{ heading3 }}</h3>
      <v-divider />
      <div v-if="stars3">
        <v-rating
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-half-full"
          length="5"
          size="15"
          :value="stars3"
          color="orange"
          readonly
        ></v-rating>
      </div>
      <p class="caption pl-3">{{ text3 }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ComparisonCard',
  props: {
    heading1: String,
    heading2: String,
    heading3: String,
    text1: String,
    text2: String,
    text3: String,
    stars1: Number,
    stars2: Number,
    stars3: Number,
  },
  data: () => ({}),
};
</script>

<style lang="sass"></style>
