<template>
  <v-container fill-height fluid class="page2">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card class="pa-2" elevation="24" style="border-radius: 12px">
          <v-card-text class="justify-center" style="padding-left: 5vw; padding-right: 5vw">
            <!-- <app1 /> -->
            <!-- <app2 /> -->
            <!-- <app3 /> -->
            <!-- <app4 /> -->
            <!-- <app5 /> -->
            <!-- <app6 /> -->
            <app-side-effects-stuff-not-to-worry-about />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import App1 from '../components/App1.vue';
import AppSideEffectsStuffNotToWorryAbout from '../components/AppSideEffectsStuffNotToWorryAbout.vue';
export default {
  name: 'App1Page',
  components: { AppSideEffectsStuffNotToWorryAbout },
  data: () => ({}),
};
</script>

<style></style>
