<template>
  <v-container fill-height fluid class="effectiveness">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'effectiveness-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="5" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h5">
                HOW WELL DOES IT PREVENT PREGNANCY?
              </v-card-title>
              <v-card-text class="white--text">
                How would you feel if you got pregnant right now? Scared? Happy? Upset? Click on the
                methods to see how effective they really are at preventing pregnancy. Knowing which
                ones work best can help you make an informed decision.
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="7" sm="12">
              <app-how-well-does-it-prevent-pregnancy />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppHowWellDoesItPreventPregnancy from '../components/AppHowWellDoesItPreventPregnancy.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'Effectiveness',
  components: { Rainbow, AppHowWellDoesItPreventPregnancy },
  methods: {
    navigateNext() {
      this.$router.push('/IUDInfo');
    },
    navigatePrev() {
      this.$router.push('/FamilyPlanningBigDeal');
    },
  },
};
</script>

<style lang="scss" scoped>
.effectiveness {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
