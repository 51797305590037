<template>
  <v-card flat>
    <v-card-text>
      <!-- <v-img :aspect-ratio="16 / 9" :src="require(`../assets/`)"> </v-img> -->
      <v-card class="ma-2" flat>
        <v-card-text class="pa-0">
          <v-row>
            <v-col class="text-center" style="background-color: #f37928">
              <v-row>
                <v-col class="white--text"> Less Effective </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(0)"
                      :class="[
                        'method_radio',
                        'no-method',
                        activeMethodIndex == 0 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(1)"
                      :class="[
                        'method_radio',
                        'male-condom',
                        activeMethodIndex == 1 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(2)"
                      :class="[
                        'method_radio',
                        'female-condom',
                        activeMethodIndex == 2 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="text-center" style="background-color: #006477">
              <v-row>
                <v-col class="white--text"> Effective </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(3)"
                      :class="[
                        'method_radio',
                        'pill',
                        activeMethodIndex == 3 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(4)"
                      :class="[
                        'method_radio',
                        'shot',
                        activeMethodIndex == 4 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="text-center" style="background-color: #669900">
              <v-row>
                <v-col class="white--text"> More Effective </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(5)"
                      :class="[
                        'method_radio',
                        'implant',
                        activeMethodIndex == 5 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(6)"
                      :class="[
                        'method_radio',
                        'copper-iud',
                        activeMethodIndex == 6 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="transparent pt-2" flat>
                    <div
                      @click="setActive(7)"
                      :class="[
                        'method_radio',
                        'sterilization',
                        activeMethodIndex == 7 ? 'selected_method' : '',
                      ]"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-card flat>
                <v-card-text>
                  <h3 style="color: #006477">
                    {{ activeMethod.text }}
                  </h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row no-gutters class="mt-5">
        <v-col
          v-for="n in 100"
          :key="n"
          lg="1"
          md="2"
          sm="2"
          :offset-lg="n % 10 == 1 ? 1 : 0"
          no-gutters
          class="text-center"
        >
          <div :class="['egg', n <= activeMethod.chance ? 'sperm' : '']" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppHowWellDoesItPreventPregnancy',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'No Method',
        chance: 85,
        text: 'No Method: 85 in 100 women will get pregnant during the first year of using no method.',
      },
      {
        method: 'Internal Condom',
        chance: 21,
        text: 'Internal Condom: 21 in 100 women will get pregnant during the first year on this method.',
      },
      {
        method: 'Male Condom',
        chance: 18,
        text: 'Male Condom: 18 in 100 women will get pregnant during the first year on this method.',
      },
      {
        method: 'Pill',
        chance: 9,
        text: 'Pill 9 in 100 women will get pregnant during the first year on this method.',
      },
      {
        method: 'Shot',
        chance: 6,
        text: 'Depo: 6 in 100 women will get pregnant during the first year on this method.',
      },
      {
        method: 'Implant',
        chance: 1,
        text: 'Implant: Less than 1 in 100 women will get pregnant during the first year on this method.',
      },
      {
        method: 'IUDC',
        chance: 1,
        text: 'IUDC: Less than 1 in 100 women will get pregnant during the first year on this method.',
      },
      {
        method: 'Sterilization',
        chance: 1,
        text: 'Sterilization: Less than 1 in 100 women will get pregnant during the first year on this method.',
      },
    ],
    activeMethod: {
      method: 'No Method',
      chance: 85,
      text: 'No Method: 85 in 100 women will get pregnant during the first year of using no method.',
    },
    activeMethodIndex: 0,
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
  },
};
</script>

<style scoped>
.egg {
  display: inline-block;
  background: no-repeat url('../assets/app1/spermegg.png') 0px center;
  height: 50px;
  width: 62px;
}

.sperm {
  background-position: -70px center;
}

.no-method {
  background: no-repeat url('../assets/app1/no-method.png') 0px center;
}

.male-condom {
  background: no-repeat url('../assets/app1/male-condom.png') 0px center;
}

.female-condom {
  background: no-repeat url('../assets/app1/female-condom.png') 0px center;
}

.pill {
  background: no-repeat url('../assets/app1/pill.png') 0px center;
}

.shot {
  background: no-repeat url('../assets/app1/shot.png') 0px center;
}

.implant {
  background: no-repeat url('../assets/app1/implant.png') 0px center;
}

.copper-iud {
  background: no-repeat url('../assets/app1/copper-iud.png') 0px center;
}

.sterilization {
  background: no-repeat url('../assets/app1/sterilization.png') 0px center;
}

.method_radio {
  display: inline-block;
  height: 60px;
  width: 80px;
  cursor: pointer;
  background-position: left center;
  background-size: cover;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.selected_method {
  background-position-x: right;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
