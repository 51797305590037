<template>
  <v-container fill-height fluid class="reasonswhy">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'reasonswhy-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                SOME OF THE REASONS WHY
              </v-card-title>
              <v-card-text class="white--text">
                Using HIV prevention helps us stay healthy and have loving, healthy relationships
                and families. We use HIV prevention for one or many reasons. Here are some reasons
                why:
              </v-card-text>
              <v-card-text>
                <speech-bubble
                  v-for="(message, index) in messages"
                  :message="message.text"
                  :position="message.position"
                  :key="index"
                />
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SpeechBubble from '../components/SpeechBubble.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'ReasonsWhy',
  components: {
    Rainbow,
    SpeechBubble,
  },
  data: () => ({
    messages: [
      {
        text: '“I am sexually active and don’t always use condoms, so I use HIV prevention.”',
        position: '1',
      },
      {
        text: '“I recently had an STI, so I use HIV prevention.”',
        position: '2',
      },
      {
        text: '“I sometimes use alcohol or drugs before sex, so I use HIV prevention”',
        position: '3',
      },
      {
        text: '“I want to be in charge of my health and feel more confident, so I use HIV prevention.”',
        position: '4',
      },
      {
        text: '“I think my partner has other partners, so I use HIV prevention”',
        position: '1',
      },
      {
        text: '“I don’t know my partner’s HIV status, so I use HIV prevention.”',
        position: '2',
      },
      {
        text: '“My partner is violent or controlling, so I use HIV prevention.”',
        position: '3',
      },
      {
        text: '“Many young women get HIV from partners who are much older than them. My partner is older than me, so I use HIV prevention.”',
        position: '4',
      },
      {
        text: '“My partner has HIV but isn’t taking HIV medication, or has been taking medication for less than six months, so I use HIV prevention.”',
        position: '1',
      },
      {
        text: '“I am a sex worker, so I use HIV prevention.”',
        position: '2',
      },
    ],
  }),
  methods: {
    navigateNext() {
      this.$router.push('/LetsGetStarted');
    },
    navigatePrev() {
      this.$router.push('/');
    },
  },
};
</script>

<style>
.reasonswhy {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
