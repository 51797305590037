<template>
  <v-card class="mb-1">
    <v-img height="250" :src="require(`../assets/${image}`)" aspect-ratio="16/9"> </v-img>
    <v-card-text v-if="textImage">
      <v-img height="75" :src="require(`../assets/${textImage}`)" aspect-ratio="16/9"> </v-img>
    </v-card-text>
    <v-card-text v-if="starRating">
      <div class="text-center">
        <v-rating
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-half-full"
          length="5"
          :size="$vuetify.breakpoint.lgAndDown ? '18' : '28'"
          :value="starRating"
          color="orange"
          readonly
        ></v-rating>
      </div>
    </v-card-text>
    <v-card-title :class="['text-h6 orange--text', starRating ? 'mt-n5' : '']">
      {{ heading }}
    </v-card-title>
    <v-card-text class="text-caption black--text">
      <div v-html="text"></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TextCard',
  props: {
    heading: String,
    text: String,
    image: String,
    textImage: String,
    starRating: Number,
  },
  data: () => ({
    reveal: false,
  }),
  methods: {
    // getImgUrl() {
    //   return require(this.image);
    // },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
