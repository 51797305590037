import { render, staticRenderFns } from "./SpeechBubble.vue?vue&type=template&id=12d85ab0&"
import script from "./SpeechBubble.vue?vue&type=script&lang=js&"
export * from "./SpeechBubble.vue?vue&type=script&lang=js&"
import style0 from "./SpeechBubble.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAvatar,VCard,VCardText,VCol,VRow,VScrollXTransition})
