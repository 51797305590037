import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    skipfamilyplanning: false,
    currentStep: 1,
    totalSteps: 8,
  },
  mutations: {
    SET_SKIP_FAMILY_PLANNING(state, skipfamilyplanning) {
      state.skipfamilyplanning = !!skipfamilyplanning;
    },
    increaseCurrentStep(state) {
      state.currentStep += 1;
    },
    decreaseCurrentStep(state) {
      state.currentStep -= 1;
    },
    initializeState(storedState, payload) {
      Object.assign('state', payload);
    },
  },
  actions: {
    setSkipFamilyPlanning({ commit }, skipfamilyplanning) {
      commit('SET_SKIP_FAMILY_PLANNING', skipfamilyplanning);
    },
  },
  getters: {
    skipfamilyplanning: (state) => state.skipfamilyplanning,
  },
  modules: {},
});
