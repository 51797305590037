<template>
  <v-container fill-height fluid class="prepandpepwhatsthedifference">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex flex-wrap align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'prepandpepwhatsthedifference-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                PrEP AND PEP: <br />WHAT’S THE DIFFERENCE?
              </v-card-title>
              <v-card-text class="white--text">
                You may have had heard about post-exposure prophylaxis (PEP), but how does it differ
                from pre-exposure prophylaxis (PrEP)? PrEP and PEP are both ways for people who are
                HIV-negative to prevent getting HIV (like condoms), but they are different in terms
                of when you take them and for how long. Here are some important differences:
              </v-card-text>
              <v-card-text>
                <v-row v-if="$vuetify.breakpoint.lgAndUp">
                  <v-col>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left text-xl-h4 text-lg-h5 green radiusTopLeft"
                              style="width: 30%"
                            ></th>
                            <th class="text-left text-xl-h4 text-lg-h5 green">
                              <span class="white--text">PrEP</span>
                            </th>
                            <th class="text-left text-xl-h4 text-lg-h5 green radiusTopRight">
                              <span class="white--text">PEP</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(card, index) in cards" :key="index">
                            <td class="pa-2 primary--text text-caption">{{ card.cardTitle }}</td>
                            <td class="pa-2 text-caption">{{ card.text1 }}</td>
                            <td class="pa-2 text-caption">{{ card.text2 }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.mdAndDown">
                  <v-col
                    lg="12"
                    md="12"
                    sm="12"
                    cols="12"
                    v-for="(card, index) in cards"
                    :key="index"
                  >
                    <v-card class="pa-2 success" dark>
                      <v-row>
                        <v-card-title class="yellow--text">{{ card.cardTitle }}</v-card-title>
                        <v-card-text>
                          <comparison-card
                            :heading1="card.heading1"
                            :heading2="card.heading2"
                            :text1="card.text1"
                            :text2="card.text2"
                          />
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ComparisonCard from '../components/ComparisonCard.vue';
import Rainbow from '../components/Rainbow.vue';

export default {
  name: 'PrepAndPepWhatsTheDifference',
  components: {
    ComparisonCard,
    Rainbow,
  },
  data: () => ({
    model: null,
    cards: [
      {
        cardTitle: 'How does it work?',
        heading1: 'PrEP',
        heading2: 'PEP',
        text1:
          'PrEP is a medicine that works only if you have the medication in your body before sex (thus pre-exposure)',
        text2:
          'PEP is an emergency medicine that works if you start it as soon as possible (<72 hrs) after sex (thus post-exposure).',
      },
      {
        cardTitle: 'When do I start?',
        heading1: 'PrEP',
        heading2: 'PEP',
        text1:
          'You can start whenever you are ready to reduce your chance of getting HIV. For example, you might use PrEP if you don’t know your partner’s HIV status or don’t always use condoms.',
        text2:
          'After sex, PEP is an emergency medicine that you have to start within 72 hours (3 days) of sex to work. For example, you could use PEP after a condom breaks or after sex without a condom.',
      },
      {
        cardTitle: 'How often do I take it?',
        heading1: 'PrEP',
        heading2: 'PEP',
        text1:
          'There are different kinds of PrEP which we’ll talk about in the next few pages. The PrEP pill is available now. You take one pill every day.',
        text2: 'Everyday.',
      },
      {
        cardTitle: 'How long do I take it?',
        heading1: 'PrEP',
        heading2: 'PEP',
        text1: 'As long as you have a chance of getting HIV.',
        text2: '28 Days.',
      },
      {
        cardTitle: 'Why should I take it?',
        heading1: 'PrEP',
        heading2: 'PEP',
        text1:
          'PrEP can give you peace of mind knowing it’s always in your body and will protect you when you have sex.',
        text2:
          'PEP can be a backup if you have sex without a condom with someone who may have HIV, but you have to go to a clinic and get it within 3 days. That’s hard for many people.',
      },
    ],
  }),
  methods: {
    navigateNext() {
      this.$router.push('/LetsTalkAboutPrepMenu');
    },
    navigatePrev() {
      this.$router.push('/WhatAreMyOptions');
    },
  },
};
</script>

<style lang="scss" scoped>
.prepandpepwhatsthedifference {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}

th.radiusTopLeft {
  border-top-left-radius: 20px;
}

th.radiusTopRight {
  border-top-right-radius: 20px;
}
</style>
