<template>
  <v-row v-if="position === '1'" justify="end" no-gutters>
    <v-col md="6">
      <v-card class="pa-1 ma-2 speech-bubble-one" color="#5aafc0" outlined dark>
        <v-card-text> {{ message }} </v-card-text>
      </v-card>
    </v-col>
    <v-col class="text-center" md="1">
      <v-avatar size="100px">
        <img src="@/assets/users/blue_silhouette.png" alt="Image2" /> </v-avatar
    ></v-col>
  </v-row>
  <v-row v-else-if="position === '2'" no-gutters>
    <v-col class="text-center" md="1">
      <v-avatar size="100px">
        <img src="@/assets/users/purple_silhouette.png" alt="Image1" /> </v-avatar
    ></v-col>
    <v-col md="6">
      <v-scroll-x-transition mode="out-in">
        <v-card class="pa-1 ma-3 speech-bubble-two" color="#a255d5" outlined dark>
          <v-card-text> {{ message }} </v-card-text>
        </v-card>
      </v-scroll-x-transition>
    </v-col>
  </v-row>
  <v-row v-else-if="position === '3'" justify="end">
    <v-col md="7">
      <v-scroll-x-transition mode="out-in">
        <v-card class="pa-1 ma-3 speech-bubble-three" color="#f37928" outlined dark>
          <v-card-text> {{ message }} </v-card-text>
        </v-card>
      </v-scroll-x-transition>
    </v-col>
    <v-col class="text-center" md="1">
      <v-avatar size="100px">
        <img src="@/assets/users/orange_silhouette.png" alt="Image1" />
      </v-avatar>
    </v-col>
  </v-row>
  <v-row v-else-if="position === '4'">
    <v-col class="text-center" md="1">
      <v-avatar size="100px">
        <img src="@/assets/users/green_silhouette.png" alt="Image1" /> </v-avatar
    ></v-col>
    <v-col md="7">
      <v-scroll-x-transition mode="out-in">
        <v-card class="pa-1 ma-3 speech-bubble-four" color="#67b826" outlined dark>
          <v-card-text> {{ message }} </v-card-text>
        </v-card>
      </v-scroll-x-transition>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SpeechBubble',
  props: {
    message: String,
    position: String,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.speech-bubble-two:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.938em solid transparent;
  border-right-color: #a255d5;
  border-left: 0;
  border-bottom: 0;
  margin-top: -0.469em;
  margin-left: -0.937em;
}
.speech-bubble-one:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.938em solid transparent;
  border-left-color: #5aafc0;
  border-right: 0;
  border-bottom: 0;
  margin-top: -0.469em;
  margin-right: -0.937em;
}
.speech-bubble-four:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.938em solid transparent;
  border-right-color: #67b826;
  border-left: 0;
  border-bottom: 0;
  margin-top: -0.469em;
  margin-left: -0.937em;
}
.speech-bubble-three:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.938em solid transparent;
  border-left-color: #f37928;
  border-right: 0;
  border-bottom: 0;
  margin-top: -0.469em;
  margin-right: -0.937em;
}
</style>
