<template>
  <v-card flat>
    <div class="wrapper">
      <div class="display">
        <div
          @click="setActive(0)"
          :class="['pill', 'info_icon', activeMethodIndex === 0 ? 'active_info_icon' : '']"
          data-name="Pill"
          data-label="Pill"
          data-description='The pill is a pill. (How&apos;s that for stating the obvious?) Some people call it "oral contraception" because you take it by mouth.'
        ></div>
        <div :class="['pill', 'map', activeMethodIndex === 0 ? 'active_map' : '']"></div>
        <div
          @click="setActive(1)"
          :class="['implant', 'info_icon', activeMethodIndex === 1 ? 'active_info_icon' : '']"
          data-name="Implant"
          data-label="Implant"
          data-description="The implant is a tiny, flexible rod (the size of a matchstick) containing hormones that's inserted under the skin of your upper arm. Once it's in, you can't feel it unless you try to find it with your fingers. It’s a quick procedure to have it put in, and then you don’t have to think about it. "
        ></div>
        <div :class="['implant', 'map', activeMethodIndex === 1 ? 'active_map' : '']"></div>
        <div
          @click="setActive(2)"
          :class="['copper-iud', 'info_icon', activeMethodIndex === 2 ? 'active_info_icon' : '']"
          data-name="Copper IUD"
          data-label="IUDC"
          data-description="The IUDC is a small T-shaped piece of flexible plastic that is put in your uterus to prevent sperm from fertilizing an egg. It's a quick procedure to have it put in, and then you don't have to think about it."
        ></div>
        <div :class="['copper-iud', 'map', activeMethodIndex === 2 ? 'active_map' : '']"></div>
        <div
          @click="setActive(3)"
          :class="['shot', 'info_icon', activeMethodIndex === 3 ? 'active_info_icon' : '']"
          data-name="Shot"
          data-label="Depo"
          data-description="Depo is a shot that keeps you from getting pregnant. You usually get it in your upper arm or hip."
        ></div>
        <div :class="['shot', 'map', activeMethodIndex === 3 ? 'active_map' : '']"></div>
        <div
          @click="setActive(4)"
          :class="['sterilization', 'info_icon', activeMethodIndex === 4 ? 'active_info_icon' : '']"
          data-name="Sterilization"
          data-label="Sterilization"
          data-description="Female sterilization is a procedure that closes or blocks your fallopian tubes so you can't get pregnant. (Your tubes are where eggs and sperm meet. If they can't meet, you won't get pregnant.) This is a permanent method."
        ></div>
        <div :class="['sterilization', 'map', activeMethodIndex === 4 ? 'active_map' : '']"></div>
        <div
          @click="setActive(5)"
          class="female-condom info_icon"
          :class="['female-condom', 'info_icon', activeMethodIndex === 5 ? 'active_info_icon' : '']"
          data-name="Female Condom"
          data-label="Internal Condom"
          data-description="The internal condom has an opening on one end and a plastic ring resting in the other. You put it into your vagina to prevent pregnancy and lower the risk of STIs, by keeping sperm inside the condom and out of your vagina. "
        ></div>
        <div :class="['female-condom', 'map', activeMethodIndex === 5 ? 'active_map' : '']"></div>
        <div
          v-if="activeMethod"
          :class="['floating_description ', activeMethod.class]"
          style="z-index: 6; display: block"
        >
          <div class="head">
            <h1>{{ activeMethod.method }}</h1>
            <div class="desc_close" @click="clear"></div>
          </div>
          <div class="body">
            {{ activeMethod.text }}
          </div>
          <div class="desc_arrow"></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppHowDoIUseIt',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'Pill',
        text: 'The pill is a pill. (How\'s that for stating the obvious?) Some people call it "oral contraception" because you take it by mouth.',
        class: 'pill',
      },
      {
        method: 'Implant',
        text: "The implant is a tiny, flexible rod (the size of a matchstick) containing hormones that's inserted under the skin of your upper arm. Once it's in, you can't feel it unless you try to find it with your fingers. It's a quick procedure to have it put in, and then you don’t have to think about it.",
        class: 'implant',
      },
      {
        method: 'IUDC',
        text: "The IUDC is a small T-shaped piece of flexible plastic that is put in your uterus to prevent sperm from fertilizing an egg. It's a quick procedure to have it put in, and then you don't have to think about it.",
        class: 'copper-iud',
      },

      {
        method: 'Depo',
        text: 'Depo is a shot that keeps you from getting pregnant. You usually get it in your upper arm or hip.',
        class: 'shot',
      },
      {
        method: 'Sterilization',
        text: "Female sterilization is a procedure that closes or blocks your fallopian tubes so you can't get pregnant. (Your tubes are where eggs and sperm meet. If they can't meet, you won't get pregnant.) This is a permanent method.",
        class: 'sterilization',
      },
      {
        method: 'Internal Condom',
        text: 'The internal condom has an opening on one end and a plastic ring resting in the other. You put it into your vagina to prevent pregnancy and lower the risk of STIs, by keeping sperm inside the condom and out of your vagina',
        class: 'female-condom',
      },
    ],
    activeMethod: '',
    activeMethodIndex: '',
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
    clear() {
      this.activeMethod = '';
      this.activeMethodIndex = '';
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

/* relative needs to be set here to ensure everything lines up foir different screen sizes */
.display {
  background: no-repeat url('../assets/app2/silhouette-female.png') center;
  background-size: 202px 733px;
  height: 794px;
  margin-top: -28px;
  width: 677px;
  min-width: 677px;
  max-width: 677px;
  position: relative;
  overflow: hidden;
}

.info_icon,
.map {
  background-image: url('../assets/app2/sprite-how-do-i-use-it.png');
  background-size: 620px 1180px;
}

.info_icon {
  cursor: pointer;
  height: 100px;
  width: 100px;
  position: absolute;
  display: block;
  z-index: 5;
  transform: scale(1);
}

.map {
  background-position-x: -360px;
  height: 150px;
  width: 256px;
  position: absolute;
  display: none;
}

/* background-position-x: this changes the icon from grey to green */

.pill.info_icon {
  background-position-x: -120px;
  background-position-y: 0px;
  left: 5%;
  top: 20%;
  overflow: hidden;
}

.pill.map {
  background-position-y: -18px;
  left: 20%;
  top: 12%;
  overflow: hidden;
}

.implant.info_icon {
  background-position-x: -120px;
  background-position-y: -260px;
  left: 5%;
  top: 45%;
  overflow: hidden;
}

.implant.map {
  background-position-y: -250px;
  left: 18%;
  top: 32%;
  overflow: hidden;
}

.copper-iud.info_icon {
  background-position-x: -120px;
  background-position-y: -560px;
  left: 5%;
  top: 68%;
  overflow: hidden;
}

.copper-iud.map {
  background-position-y: -400px;
  height: 215px;
  left: 18%;
  top: 48%;
  overflow: hidden;
}

.shot.info_icon {
  background-position-x: -120px;
  background-position-y: -700px;
  left: 75%;
  top: 20%;
  overflow: hidden;
}

.shot.map {
  background-position-y: -700px;
  height: 100px;
  left: 60%;
  top: 20%;
  overflow: hidden;
}

.sterilization.info_icon {
  background-position-x: -120px;
  background-position-y: -840px;
  left: 75%;
  top: 45%;
  overflow: hidden;
}

.sterilization.map {
  background-position-y: -800px;
  height: 130px;
  left: 43%;
  top: 40%;
  overflow: hidden;
}

.female-condom.info_icon {
  background-position-x: -120px;
  background-position-y: -980px;
  left: 75%;
  top: 65%;
  overflow: hidden;
}

.female-condom.map {
  background-position-y: -970px;
  height: 220px;
  left: 42%;
  top: 45%;
  overflow: hidden;
}

.active_info_icon {
  background-position-x: -240px !important;
}

.active_map {
  display: block !important;
}

.floating_description,
#your_questions_explanatory_box {
  background-color: #669900;
  border: 0 none;
  color: white;
  display: none;
  font-size: 11px;
  font-style: normal;
  line-height: 19px;
  position: absolute;
  text-align: left;
  width: 250px;
}

.floating_description .head,
#your_questions_explanatory_box .head {
  padding: 0px 10px 0px 10px;
  border-bottom: 1px dotted white;
  height: 32px;
}

.floating_description .head h1,
#your_questions_explanatory_box .head h1 {
  font-family: 'Georgia', Georgia, Serif;
  line-height: 0px;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
}

.floating_description .desc_close,
#your_questions_explanatory_box .desc_close {
  position: relative;
  display: inline-block;
  left: 216px;
  top: -23px;
  height: 15px;
  width: 16px;
  cursor: pointer;
  background: no-repeat url(../assets/app2/x-icon.png);
}

.floating_description .body,
#your_questions_explanatory_box .body {
  padding: 6px 10px 10px 10px;
}

.floating_description .desc_arrow,
#your_questions_explanatory_box .desc_arrow {
  border-bottom: 12px solid #669900;
  border-top: 0px solid #669900;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  font-size: 0px;
  left: 137px;
  line-height: 0%;
  position: absolute;
  top: -12px;
  width: 0px;
}

.floating_description.pill {
  left: 48%;
  top: 20%;
}

.floating_description.pill .desc_arrow {
  left: 10px;
}

.floating_description.implant {
  left: 20%;
  top: 8%;
}

.floating_description.implant .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.copper-iud {
  left: 30%;
  top: 28%;
}

.floating_description.copper-iud .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.shot {
  left: 35%;
  top: 31%;
}

.floating_description.shot .desc_arrow {
  left: 187px;
}

.floating_description.sterilization {
  left: 31%;
  top: 21%;
}

.floating_description.sterilization .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.female-condom {
  left: 43%;
  top: 21%;
}

.floating_description.female-condom .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 28px;
}

@media only screen and (max-width: 600px) {
  .display {
    transform: scale(0.5);
  }

  .floating_description {
    transform: scale(1.5);
  }

  .floating_description .desc_arrow,
  #your_questions_explanatory_box .desc_arrow {
    display: none;
  }
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
