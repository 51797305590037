<template>
  <v-container fill-height fluid class="whataremyoptions">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card ',
            $vuetify.breakpoint.lgAndUp ? 'home-card-background' : '',
          ]"
          elevation="20"
          style="min-height: 80vh"
        >
          <v-row>
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                WHAT ARE MY OPTIONS TO PREVENT HIV?
              </v-card-title>
              <v-card-text>
                People who are HIV-negative can use HIV prevention methods to lower their chance of
                getting HIV. These may be best used in combination
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(card, index) in cards"
                    cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                    :key="index"
                    class="d-flex"
                    style="flex-direction: column"
                  >
                    <hover-card :heading="card.heading" :text="card.text" :image="card.image" />
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HoverCard from '../components/HoverCard.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'WhatAreMyOptions',
  components: {
    Rainbow,
    HoverCard,
  },
  data: () => ({
    cards: [
      {
        heading: 'PrEP: pre-exposure prophylaxis',
        text: 'HIV-negative people can take anti-HIV medicine to prevent HIV before and after sex, or before and after injecting drugs. You can take PrEP now as a daily pill, and soon PrEP will be available as a vaginal ring or as an injection. PrEP is safe, has few side effects, and is highly effective. In the next few minutes, we’ll talk a lot more about different types of PrEP. <a href="/PrepAndPepWhatsTheDifference">Click here to learn about about PrEP</a>.',
        image: 'prep.jpg',
      },
      {
        heading: 'PEP: post-exposure prophylaxis',
        text: 'HIV-negative people can take anti-HIV medicines after sex or injecting drugs to prevent HIV. PEP is an emergency medicine that has to be started within 3 days of sex or injection to work. You take PEP only for a month. PEP is very safe and effective. <a href="/PrepAndPepWhatsTheDifference">Click here to learn about about PEP</a>.',
        image: 'pep.jpg',
      },
      {
        heading: 'Condoms (Male and Female)',
        text: 'Male and female condoms are very effective at preventing HIV if they’re used correctly every single time you have sex. Condoms are the only HIV prevention method that also prevent against other sexually transmitted infections and pregnancy!',
        image: 'condom.jpg',
      },
      {
        heading: 'Have fewer sex partners',
        text: 'Decreasing your number of sex partners can lower your chance of getting HIV, but this method works best if you know your partner’s HIV status.',
        image: 'partners.jpg',
      },
      {
        heading: 'Knowing if my partner has HIV',
        text: 'Knowing your partner’s HIV status is a very effective way of preventing HIV, especially if you get tested together regularly.',
        image: 'hiv_status.jpg',
      },
      {
        heading: 'If my partner has HIV, they take medication',
        text: 'If your partner has HIV, is taking HIV medication every day, and has been on treatment for 6 months or more, your risk of getting HIV is extremely low. That’s why knowing your partners’ HIV status is so important!',
        image: 'medication.jpg',
      },
    ],
  }),
  methods: {
    navigateNext() {
      this.$router.push('/PrepAndPepWhatsTheDifference');
    },
    navigatePrev() {
      this.$router.push('/LetsGetStarted');
    },
  },
};
</script>

<style lang="scss" scoped>
.whataremyoptions {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
