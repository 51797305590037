<template>
  <v-container fill-height fluid class="sideeffectscontra">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'sideeffectscontra-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="5" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h5">
                A CLOSER LOOK AT SIDE EFFECTS
              </v-card-title>
              <v-card-text class="white--text">
                Now that you know about the potential side effects of different types of family
                planning, take a closer look by clicking on each method.
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="7" sm="12">
              <app-a-closer-look-at-side-effects />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppACloserLookAtSideEffects from '../components/AppACloserLookAtSideEffects.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  components: { Rainbow, AppACloserLookAtSideEffects },
  name: 'SideEffectsContra',
  methods: {
    navigateNext() {
      this.$router.push('/fertility_future');
    },
    navigatePrev() {
      this.$router.push('/SideEffectsMain');
    },
  },
};
</script>

<style lang="scss" scoped>
.sideeffectscontra {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
