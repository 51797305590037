<template>
  <v-container fill-height fluid class="howoften">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'howoften-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="5" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h5">
                HOW OFTEN DO I HAVE TO REMEMBER IT?
              </v-card-title>
              <v-card-text class="white--text">
                Click the icons to learn about how often each method is used. Using family planning
                correctly and consistently is extremely important if you don't want to get pregnant.
                (That means every time, all the time.) It's best to go with a method that you find
                convenient and easy to use.
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="7" sm="12">
              <app-how-often-do-i-have-to-remember-it />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppHowOftenDoIHaveToRememberIt from '../components/AppHowOftenDoIHaveToRememberIt.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  components: { Rainbow, AppHowOftenDoIHaveToRememberIt },
  name: 'Home',
  methods: {
    navigateNext() {
      this.$router.push('/SideEffectsInfo');
    },
    navigatePrev() {
      this.$router.push('/emergency_contraception');
    },
  },
};
</script>

<style lang="scss" scoped>
.howoften {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
