<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col v-for="(card, index) in cards" cols="12" lg="4" md="12" sm="12" :key="index">
          <hover-card :heading="card.heading" :text="card.text" :image="card.image" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import HoverCard from '../components/HoverCard.vue';
export default {
  name: 'AppACloserLookAtSideEffects',
  components: {
    HoverCard,
  },
  data: () => ({
    reveal: false,
    cards: [
      {
        heading: 'Sterilization',
        text: 'HIV-negative people can take an HIV prevention pill daily to prevent HIV. You can take PrEP as longs you feel you have an increased chance of getting HIV. Your situation may change and you do not have to take PrEP for life. PrEP is safe, has few side effects, and is highly effective if you take it every day.',
        image: 'prep.jpg',
      },
      {
        heading: 'Copper IUD',
        text: 'Male and female condoms are very effective at preventing HIV if they’re used correctly every single time you have sex. Condoms are the only HIV prevention method that also prevent against other sexually transmitted infections and pregnancy!',
        image: 'condom.jpg',
      },
      {
        heading: 'Implant',
        text: 'Decreasing your number of sex partners can lower your chance of getting HIV, but this method works best if you know your partner’s HIV status.',
        image: 'partners.jpg',
      },
      {
        heading: 'Depo',
        text: 'Knowing your partner’s HIV status is a very effective way of preventing HIV, especially if you get tested together on a regular basis.',
        image: 'hiv_status.jpg',
      },
      {
        heading: 'Pill',
        text: 'If your partner has HIV, is taking HIV medication every day, and has been on treatment for 6 months or more, your risk of getting HIV is extremely low. That’s why knowing your partners’ HIV status is so important!',
        image: 'medication.jpg',
      },
      {
        heading: 'Male Condom',
        text: 'If your partner has HIV, is taking HIV medication every day, and has been on treatment for 6 months or more, your risk of getting HIV is extremely low. That’s why knowing your partners’ HIV status is so important!',
        image: 'medication.jpg',
      },
      {
        heading: 'Internal Condom',
        text: 'If your partner has HIV, is taking HIV medication every day, and has been on treatment for 6 months or more, your risk of getting HIV is extremely low. That’s why knowing your partners’ HIV status is so important!',
        image: 'medication.jpg',
      },
    ],
  }),
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
