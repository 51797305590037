<template>
  <v-container fill-height fluid class="familyplanningbigdeal">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="['pa-2 d-flex align-center colored-card', $vuetify.breakpoint.lgAndUp ? '' : '']"
          elevation="20"
          style="min-height: 80vh"
        >
          <v-row>
            <v-col lg="8" offset-lg="2" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                FAMILY PLANNING IS KIND OF A BIG DEAL. <br />
                NEED HELP WITH YOUR DECISION?
              </v-card-title>
              <v-card-text class="white--text">
                You’ve got to love options, especially when it comes to family planning, but
                figuring out the best method for you can be a bit overwhelming. That's why we're
                helping make the decision-making process much easier.
                <br />
                <br />
                In just 10 minutes or less,this guide will cover important basics about all the
                methods out there and ask you some questions about what you want from family
                planning, so you can find the ones that will work for you. Even if you already have
                a method in mind, it’s always good to know your options.
                <br />
                <br />
                It will also help you decide which questions to ask your health care provider. (They
                really like it when you ask questions, so walking in with your list is totally the
                way to go!)
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'FamilyPlanningBigDeal',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/effectiveness');
    },
    navigatePrev() {
      this.$router.push('/LearnMore');
    },
  },
};
</script>

<style lang="scss" scoped>
.familyplanningbigdeal {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
.familyplanningbigdeal-card-background {
  background-image: url('../assets/page8-bg-card.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
}
</style>
