<template>
  <v-card>
    <v-card-text>
      <v-tabs v-model="model" centered slider-color="yellow" show-arrows>
        <v-tab :key="'good'" :href="'#good'"> Good Stuff </v-tab>
        <v-tab :key="'annoying'" :href="'#annoying'"> Annoying Stuff </v-tab>
        <v-tab :key="'noworries'" :href="'#noworries'"> Stuff Not To Worry About </v-tab>
      </v-tabs>

      <v-tabs-items v-model="model">
        <v-tab-item :key="'good'" :value="'good'">
          <v-card flat>
            <v-card-title>
              In addition to preventing pregnancy, family planning can have positive effects too.
              Would any of these thrill you?
            </v-card-title>
            <v-card-text>
              <app-side-effects-good-stuff />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="'annoying'" :value="'annoying'">
          <v-card flat>
            <v-card-title>
              Would any of these side effects bother you? Keep that in mind when choosing your
              method.
            </v-card-title>
            <v-card-text>
              <app-side-effects-annoying-stuff />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="'noworries'" :value="'noworries'">
          <v-card flat>
            <v-card-title>
              Sometimes you or a friend might experience a problem that you think is caused by your
              method, but that doesn't always mean it is.
            </v-card-title>
            <v-card-text>
              <app-side-effects-stuff-not-to-worry-about />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import AppSideEffectsAnnoyingStuff from './AppSideEffectsAnnoyingStuff.vue';
import AppSideEffectsGoodStuff from './AppSideEffectsGoodStuff.vue';
import AppSideEffectsStuffNotToWorryAbout from './AppSideEffectsStuffNotToWorryAbout.vue';
export default {
  components: {
    AppSideEffectsGoodStuff,
    AppSideEffectsAnnoyingStuff,
    AppSideEffectsStuffNotToWorryAbout,
  },
  name: 'AppSideEffectsWrapper',
  data: () => ({
    model: 'tab-2',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
