<template>
  <v-card flat>
    <div class="wrapper">
      <div class="display">
        <div
          @click="setActive(0)"
          :class="['male-condom', 'info_icon', activeMethodIndex === 0 ? 'active_info_icon' : '']"
          data-name="male-condom"
          data-label="male-condom"
          data-description='The male-condom is a male-condom. (How&apos;s that for stating the obvious?) Some people call it "oral contraception" because you take it by mouth.'
        ></div>
        <div :class="['male-condom', 'map', activeMethodIndex === 0 ? 'active_map' : '']"></div>
        <div
          @click="setActive(1)"
          :class="['vasectomy', 'info_icon', activeMethodIndex === 1 ? 'active_info_icon' : '']"
          data-name="vasectomy"
          data-label="vasectomy"
          data-description="The vasectomy is a tiny, flexible rod (the size of a matchstick) containing hormones that's inserted under the skin of your upper arm. Once it's in, you can't feel it unless you try to find it with your fingers. It’s a quick procedure to have it put in, and then you don’t have to think about it. "
        ></div>
        <div :class="['vasectomy', 'map', activeMethodIndex === 1 ? 'active_map' : '']"></div>
        <div
          v-if="activeMethod"
          :class="['floating_description ', activeMethod.class]"
          style="z-index: 6; display: block"
        >
          <div class="head">
            <h1>{{ activeMethod.method }}</h1>
            <div class="desc_close" @click="clear"></div>
          </div>
          <div class="body">
            {{ activeMethod.text }}
          </div>
          <div class="desc_arrow"></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppFamilyPlanningThatGuysUse',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'male-condom',
        text: "You slip one over a guy's penis to prevent pregnancy and lower the risk of STIs by keeping his sperm inside the condom and out of your vagina.",
        class: 'male-condom',
      },
      {
        method: 'vasectomy',
        text: "This small, in-office surgery blocks the tubes that carry a man's sperm. It is permanent, so he has to be sure he doesn't want to have kids in the future.",
        class: 'vasectomy',
      },
    ],
    activeMethod: '',
    activeMethodIndex: '',
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
    clear() {
      this.activeMethod = '';
      this.activeMethodIndex = '';
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

/* relative needs to be set here to ensure everything lines up foir different screen sizes */
.display {
  background: no-repeat url('../assets/app3/silhouette-male.png') center;
  height: 794px;
  margin-top: -28px;
  width: 677px;
  min-width: 677px;
  max-width: 677px;
  position: relative;
  overflow: hidden;
}

.info_icon,
.map {
  background-image: url('../assets/app3/sprite-how-do-i-use-it.png');
  background-size: 800px 1700px;
}

.info_icon {
  cursor: pointer;
  height: 100px;
  width: 100px;
  position: absolute;
  display: block;
  z-index: 5;
  transform: scale(1);
}

.map {
  background-position-x: -360px;
  height: 150px;
  width: 256px;
  position: absolute;
  display: none;
}

/* background-position-x: this changes the icon from grey to green */

.male-condom.info_icon {
  background-position-x: -120px;
  background-position-y: -1540px;
  left: 75%;
  top: 45%;
  overflow: hidden;
}

.male-condom.map {
  background-position-y: -1540px;
  left: 50%;
  top: 45%;
  overflow: hidden;
}

.vasectomy.info_icon {
  background-position-x: -120px;
  background-position-y: -1420px;
  left: 5%;
  top: 45%;
  overflow: hidden;
}

.vasectomy.map {
  background-position-y: -1420px;
  height: 100px;
  left: 19%;
  top: 50%;
  overflow: hidden;
}

.active_info_icon {
  background-position-x: -240px !important;
}

.active_map {
  display: block !important;
}

.floating_description,
#your_questions_explanatory_box {
  background-color: #669900;
  border: 0 none;
  color: white;
  display: none;
  font-size: 11px;
  font-style: normal;
  line-height: 19px;
  position: absolute;
  text-align: left;
  width: 250px;
}

.floating_description .head,
#your_questions_explanatory_box .head {
  padding: 0px 10px 0px 10px;
  border-bottom: 1px dotted white;
  height: 32px;
}

.floating_description .head h1,
#your_questions_explanatory_box .head h1 {
  font-family: 'Georgia', Georgia, Serif;
  line-height: 0px;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
}

.floating_description .desc_close,
#your_questions_explanatory_box .desc_close {
  position: relative;
  display: inline-block;
  left: 216px;
  top: -23px;
  height: 15px;
  width: 16px;
  cursor: pointer;
  background: no-repeat url(../assets/app3/x-icon.png);
}

.floating_description .body,
#your_questions_explanatory_box .body {
  padding: 6px 10px 10px 10px;
}

.floating_description .desc_arrow,
#your_questions_explanatory_box .desc_arrow {
  border-bottom: 12px solid #669900;
  border-top: 0px solid #669900;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  font-size: 0px;
  left: 137px;
  line-height: 0%;
  position: absolute;
  top: -12px;
  width: 0px;
}

.floating_description.male-condom {
  left: 46%;
  top: 60%;
}

.floating_description.male-condom .desc_arrow {
  left: 31px;
}

.floating_description.vasectomy {
  left: 22%;
  top: 60%;
}

.floating_description.vasectomy .desc_arrow {
  left: 187px;
}

@media only screen and (max-width: 600px) {
  .display {
    transform: scale(0.5);
  }

  .floating_description {
    transform: scale(1.5);
  }

  .floating_description .desc_arrow,
  #your_questions_explanatory_box .desc_arrow {
    display: none;
  }
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
