<template>
  <div>
    <v-card class="my-4 d-flex" flat>
      <v-row>
        <v-col cols="12" class="d-flex align-start">
          <v-icon color="red">mdi-close-circle</v-icon>
          <span class="black--text text-caption mx-2 font-italic">{{ text2 }}</span>
        </v-col>
        <v-col cols="12" class="d-flex align-start">
          <v-icon color="green">mdi-check-circle</v-icon>
          <span class="black--text text-caption mx-2">{{ text1 }}</span>
        </v-col>
      </v-row>
    </v-card>
    <v-divider color="indigo"></v-divider>
  </div>
  <!-- <v-row>
    <v-col cols="12" lg="6" class="text-center">
      <v-icon large color="green">mdi-check</v-icon>
      <v-divider />
      <p class="caption">{{ text1 }}</p>
    </v-col>
    <v-col cols="12" lg="6" class="text-center">
      <v-icon large color="red"> mdi-close </v-icon>
      <v-divider />
      <p class="caption">{{ text2 }}</p></v-col
    >
  </v-row> -->
</template>

<script>
export default {
  name: 'TrueFalseCard',
  props: {
    heading1: String,
    heading2: String,
    text1: String,
    text2: String,
  },
  data: () => ({}),
};
</script>
<style lang="sass"></style>
