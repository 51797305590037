<template>
  <v-container fill-height fluid class="learnmore">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card ',
            $vuetify.breakpoint.lgAndUp ? 'learnmore-card-background' : '',
          ]"
          elevation="20"
          style="min-height: 80vh"
        >
          <v-row>
            <v-col lg="4" offset-lg="8" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                LET'S GET STARTED
              </v-card-title>
              <v-card-text class="white--text">
                Now that we’ve gone through some information about HIV prevention, talk to your
                provider about your options and if you want PrEP. Next, we’ll talk more about your
                options for family planning.
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'LearnMore',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/FamilyPlanningBigDeal');
    },
    navigatePrev() {
      this.$router.push('/MethodChoice');
    },
  },
};
</script>

<style lang="scss" scoped>
.learnmore {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
.learnmore-card-background {
  background-image: url('../assets/family_planning.png');
  background-position: 200 300;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
