<template>
  <v-container fill-height fluid class="sideeffectsmain">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'sideeffectsmain-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="5" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h5">
                ARE THERE ANY SIDE EFFECTS?
              </v-card-title>
              <v-card-text class="white--text">
                Click on the images to see some side effects and perks that come with using family
                planning.
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="7" sm="12">
              <app-side-effects-wrapper />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppSideEffectsWrapper from '../components/AppSideEffectsWrapper.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  components: { Rainbow, AppSideEffectsWrapper },
  name: 'SideEffectsMain',
  methods: {
    navigateNext() {
      this.$router.push('/side_effects_contra');
    },
    navigatePrev() {
      this.$router.push('/SideEffectsInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.sideeffectsmain {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
