<template>
  <v-container fill-height fluid class="finish">
    <v-row>
      <v-col cols="12" lg="6" offset-lg="3" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'sideffectsinfo-card-background' : '',
          ]"
          elevation="20"
        >
          <v-card-title class="orange--text text-xl-h4 text-lg-h5"> The End. </v-card-title>
          <v-card-text class="text-center">
            Feel free to start over.
            <rainbow />
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn large type="next" @click="navigateNext"> Start Again </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Finish',
  components: {},
  computed: {
    ...mapGetters(['skipfamilyplanning']),
  },
  methods: {
    navigateNext() {
      if (this.skipfamilyplanning) {
        this.$router.push({ name: 'Home', query: { skipfamilyplanning: 'true' } });
      } else {
        this.$router.push({ name: 'Home' });
      }
    },
    navigatePrev() {
      if (this.skipfamilyplanning) {
        return this.$router.push('/MethodChoice');
      }
      return this.$router.push('/fertility_future'); //go to /fertility_future when routing with family planning..../MethodChoice when shorcutting family planning
    },
  },
};
</script>

<style>
@import url('../styles/finish.scss');
</style>
