<template>
  <v-card flat>
    <div class="wrapper">
      <div class="display">
        <div
          @click="setActive(0)"
          :class="['less-acne', 'info_icon', activeMethodIndex === 0 ? 'active_info_icon' : '']"
          data-name="Less Acne"
          data-label="Less Acne"
          data-description="The pill may improve acne and make your skin clearer."
        ></div>
        <div :class="['less-acne', 'map', activeMethodIndex === 0 ? 'active_map' : '']"></div>

        <div
          @click="setActive(1)"
          :class="[
            'cancer-prevention',
            'info_icon',
            activeMethodIndex === 1 ? 'active_info_icon' : '',
          ]"
          data-name="Cancer Prevention"
          data-label="Cancer Prevention"
          data-description="Did you know that family planning can actually prevent cancer? The pill and Depo both decrease your risk of ovarian and uterine cancer. An IUDC also lowers the risk of uterine cancer."
        ></div>
        <div
          :class="['cancer-prevention', 'map', activeMethodIndex === 1 ? 'active_map' : '']"
        ></div>

        <div
          @click="setActive(2)"
          :class="['less-cramping', 'info_icon', activeMethodIndex === 2 ? 'active_info_icon' : '']"
          data-name="Less Cramping"
          data-label="Less Cramping"
          data-description="The pill and Depo can make your periods less painful."
        ></div>
        <div :class="['less-cramping', 'map', activeMethodIndex === 2 ? 'active_map' : '']"></div>

        <div
          @click="setActive(3)"
          :class="[
            'lack-of-bleeding',
            'info_icon',
            activeMethodIndex === 3 ? 'active_info_icon' : '',
          ]"
          data-name="Lack of Bleeding"
          data-label="Lack of Bleeding"
          data-description="Some methods (like Depo) can make your period go away completely. While many women think this is great, others worry if it's okay to not get a monthly period. The good news is that it's OK for your health to skip your period on these methods. And don't worry–nothing is building up inside you."
        ></div>
        <div
          :class="['lack-of-bleeding', 'map', activeMethodIndex === 3 ? 'active_map' : '']"
        ></div>

        <div
          @click="setActive(4)"
          :class="[
            'lighter-periods',
            'info_icon',
            activeMethodIndex === 4 ? 'active_info_icon' : '',
          ]"
          class="lighter-periods info_icon"
          data-name="Lighter Periods"
          data-label="Lighter Periods"
          data-description="While most women have regular bleeding with the pill, their periods may become lighter. That means you may have more energy."
        ></div>
        <div :class="['lighter-periods', 'map', activeMethodIndex === 4 ? 'active_map' : '']"></div>

        <div
          v-if="activeMethod"
          :class="['floating_description ', activeMethod.class]"
          style="z-index: 6; display: block"
        >
          <div class="head">
            <h1>{{ activeMethod.method }}</h1>
            <div class="desc_close" @click="clear"></div>
          </div>
          <div class="body">
            {{ activeMethod.text }}
          </div>
          <div class="desc_arrow"></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppSideEffectsGoodStuff',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'Less Acne',
        text: 'The pill may improve acne and make your skin clearer.',
        class: 'less-acne',
      },
      {
        method: 'Cancer Prevention',
        text: 'Did you know that family planning can actually prevent cancer? The pill and Depo both decrease your risk of ovarian and uterine cancer. An IUDC also lowers the risk of uterine cancer.',
        class: 'cancer-prevention',
      },
      {
        method: 'Less Cramping',
        text: 'The pill and Depo can make your periods less painful',
        class: 'less-cramping',
      },

      {
        method: 'Lack of Bleeding',
        text: "Some methods (like Depo) can make your period go away completely. While many women think this is great, others worry if it's okay to not get a monthly period. The good news is that it's OK for your health to skip your period on these methods. And don't worry–nothing is building up inside you.",
        class: 'lack-of-bleeding',
      },
      {
        method: 'Lighter Periods',
        text: 'While most women have regular bleeding with the pill, their periods may become lighter. That means you may have more energy.',
        class: 'lighter-periods',
      },
    ],
    activeMethod: '',
    activeMethodIndex: '',
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
    clear() {
      this.activeMethod = '';
      this.activeMethodIndex = '';
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

/* relative needs to be set here to ensure everything lines up foir different screen sizes */
.display {
  background: no-repeat url('../assets/app2/silhouette-female.png') center;
  background-size: 202px 733px;
  height: 794px;
  margin-top: -28px;
  width: 677px;
  min-width: 677px;
  max-width: 677px;
  position: relative;
  overflow: hidden;
}

.info_icon,
.map {
  background-image: url('../assets/app5/sprite-side-effects.png');
  background-size: 750px 2310px;
}

.info_icon {
  cursor: pointer;
  height: 100px;
  width: 100px;
  position: absolute;
  display: block;
  z-index: 5;
  transform: scale(1);
}

.map {
  background-position-x: -357px;
  height: 120px;
  width: 225px;
  position: absolute;
  display: none;
}

/* background-position-x: this changes the icon from grey to green */

.less-acne.info_icon {
  background-position-x: -120px;
  background-position-y: -50px;
  left: 5%;
  top: 20%;
  overflow: hidden;
}

.less-acne.map {
  background-position-y: -50px;
  left: 20%;
  top: 12%;
  overflow: hidden;
}

.cancer-prevention.info_icon {
  background-position-x: -120px;
  background-position-y: -170px;
  left: 5%;
  top: 45%;
  overflow: hidden;
}

.cancer-prevention.map {
  background-position-y: -170px;
  height: 61px;
  left: 18%;
  top: 48%;
  overflow: hidden;
}

.less-cramping.info_icon {
  background-position-x: -120px;
  background-position-y: -290px;
  left: 5%;
  top: 68%;
  overflow: hidden;
}

.less-cramping.map {
  background-position-y: -50px;
  left: 18%;
  top: 58%;
  overflow: hidden;
}

.lack-of-bleeding.info_icon {
  background-position-x: -120px;
  background-position-y: -410px;
  left: 75%;
  top: 20%;
  overflow: hidden;
}

.lack-of-bleeding.map {
  background-position-y: -379px;
  width: 229px;
  left: 50%;
  top: 30%;
  overflow: hidden;
}

.lighter-periods.info_icon {
  background-position-x: -120px;
  background-position-y: -530px;
  left: 75%;
  top: 45%;
  overflow: hidden;
}

.lighter-periods.map {
  background-position-y: -500px;
  height: 60px;
  left: 48%;
  top: 43%;
  overflow: hidden;
}

.active_info_icon {
  background-position-x: -240px !important;
}

.active_map {
  display: block !important;
}

.floating_description,
#your_questions_explanatory_box {
  background-color: #669900;
  border: 0 none;
  color: white;
  display: none;
  font-size: 11px;
  font-style: normal;
  line-height: 19px;
  position: absolute;
  text-align: left;
  width: 250px;
}

.floating_description .head,
#your_questions_explanatory_box .head {
  padding: 0px 10px 0px 10px;
  border-bottom: 1px dotted white;
  height: 32px;
}

.floating_description .head h1,
#your_questions_explanatory_box .head h1 {
  font-family: 'Georgia', Georgia, Serif;
  line-height: 0px;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
}

.floating_description .desc_close,
#your_questions_explanatory_box .desc_close {
  position: relative;
  display: inline-block;
  left: 216px;
  top: -23px;
  height: 15px;
  width: 16px;
  cursor: pointer;
  background: no-repeat url(../assets/app2/x-icon.png);
}

.floating_description .body,
#your_questions_explanatory_box .body {
  padding: 6px 10px 10px 10px;
}

.floating_description .desc_arrow,
#your_questions_explanatory_box .desc_arrow {
  border-bottom: 12px solid #669900;
  border-top: 0px solid #669900;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  font-size: 0px;
  left: 137px;
  line-height: 0%;
  position: absolute;
  top: -12px;
  width: 0px;
}

.floating_description.less-acne {
  left: 48%;
  top: 20%;
}

.floating_description.less-acne .desc_arrow {
  left: 10px;
}

.floating_description.cancer-prevention {
  left: 30%;
  top: 28%;
}

.floating_description.cancer-prevention .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.less-cramping {
  left: 30%;
  top: 43%;
}

.floating_description.less-cramping .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.lack-of-bleeding {
  left: 22%;
  top: 48%;
}

.floating_description.lack-of-bleeding .desc_arrow {
  left: 187px;
}

.floating_description.lighter-periods {
  left: 30%;
  top: 26%;
}

.floating_description.lighter-periods .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

@media only screen and (max-width: 600px) {
  .display {
    transform: scale(0.5);
  }

  .floating_description {
    transform: scale(1.5);
  }

  .floating_description .desc_arrow,
  #your_questions_explanatory_box .desc_arrow {
    display: none;
  }
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
