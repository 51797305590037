<template>
  <v-container fill-height fluid class="contrainfo">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'contrainfo-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                INFO QUICKIE: EMERGENCY CONTRACEPTION
              </v-card-title>
              <v-card-text class="white--text">
                <v-row>
                  <v-col lg="6" md="12" sm="12" cols="12">
                    Here's the deal with emergency contraception: Always think of it as plan B.
                    <br />
                    <br />
                    We want you to choose the best method for you and use it all the time, but
                    sometimes that doesn't happen. If that happens to you, don't forget there are
                    options that prevent pregnancy after sex, including the IUDC and emergency
                    contraception pills.
                    <br />
                    <br />
                    Emergency contraception works up to 5 days (the pill) or 7 days (IUDC) after
                    unprotected sex. (But the sooner the better, so try not to leave it to the last
                    minute.)
                    <br />
                    <br />
                    When it comes to EC, remember that timing is everything and that you and your
                    friends have this option as a backup... just in case.
                  </v-col>
                  <v-col lg="4" offset-lg="1" md="12" sm="12" cols="12">
                    <v-img class="center" src="../assets/contra-img.svg" />
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'contra Info',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/how_often');
    },
    navigatePrev() {
      this.$router.push('/how_to_men');
    },
  },
};
</script>

<style lang="scss" scoped>
.contrainfo {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
