<template>
  <v-card flat>
    <div class="wrapper">
      <div class="display">
        <div
          @click="setActive(0)"
          :class="['depression', 'info_icon', activeMethodIndex === 0 ? 'active_info_icon' : '']"
          data-name="Depression"
          data-label="Depression"
          data-description="While Depo or the implant may cause a few women to feel sad, no other methods have been proven to increase your risk of depression. Sometimes women report feeling better on contraception since they aren't worried about pregnancy scares."
        ></div>
        <div :class="['depression', 'map', activeMethodIndex === 0 ? 'active_map' : '']"></div>
        <div
          @click="setActive(1)"
          :class="['nausea', 'info_icon', activeMethodIndex === 1 ? 'active_info_icon' : '']"
          data-name="Nausea"
          data-label="Nausea"
          data-description="Most women on contraception don't feel sick to their stomach because of their method. But, if it does happen, it usually goes away after a month or two."
        ></div>
        <div :class="['nausea', 'map', activeMethodIndex === 1 ? 'active_map' : '']"></div>
        <div
          @click="setActive(2)"
          :class="[
            'breast-tenderness',
            'info_icon',
            activeMethodIndex === 2 ? 'active_info_icon' : '',
          ]"
          data-name="Breast Tenderness"
          data-label="Breast Tenderness"
          data-description="While some women report breast tenderness with the pill, it gets better after a few months. In time, these methods may actually make breast pain better."
        ></div>
        <div
          :class="['breast-tenderness', 'map', activeMethodIndex === 2 ? 'active_map' : '']"
        ></div>
        <div
          @click="setActive(3)"
          :class="['weight-gain', 'info_icon', activeMethodIndex === 3 ? 'active_info_icon' : '']"
          data-name="Weight Gain"
          data-label="Weight Gain"
          data-description="Depo may cause some weight gain. This is generally no more than 5-10 pounds."
        ></div>
        <div :class="['weight-gain', 'map', activeMethodIndex === 3 ? 'active_map' : '']"></div>

        <div
          v-if="activeMethod"
          :class="['floating_description ', activeMethod.class]"
          style="z-index: 6; display: block"
        >
          <div class="head">
            <h1>{{ activeMethod.method }}</h1>
            <div class="desc_close" @click="clear"></div>
          </div>
          <div class="body">
            {{ activeMethod.text }}
          </div>
          <div class="desc_arrow"></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppSideEffectsStuffNotToWorryAbout',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'Depression',
        text: "While Depo or the implant may cause a few women to feel sad, no other methods have been proven to increase your risk of depression. Sometimes women report feeling better on contraception since they aren't worried about pregnancy scares.",
        class: 'depression',
      },
      {
        method: 'Nausea',
        text: "Most women on contraception don't feel sick to their stomach because of their method. But, if it does happen, it usually goes away after a month or two.",
        class: 'nausea',
      },
      {
        method: 'Breast Tenderness',
        text: 'While some women report breast tenderness with the pill, it gets better after a few months. In time, these methods may actually make breast pain better.',
        class: 'breast-tenderness',
      },
      {
        method: 'Weight Gain',
        text: "Other than Depo, no other methods cause increased weight. Some women may gain weight anyway, but it's not because of their contraception.",
        class: 'weight-gain',
      },
    ],
    activeMethod: '',
    activeMethodIndex: '',
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
    clear() {
      this.activeMethod = '';
      this.activeMethodIndex = '';
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

/* relative needs to be set here to ensure everything lines up foir different screen sizes */
.display {
  background: no-repeat url('../assets/app2/silhouette-female.png') center;
  background-size: 202px 733px;
  height: 794px;
  margin-top: -28px;
  width: 677px;
  min-width: 677px;
  max-width: 677px;
  position: relative;
  overflow: hidden;
}

.info_icon,
.map {
  background-image: url('../assets/app5/sprite-side-effects.png');
  background-size: 750px 2310px;
}

.info_icon {
  cursor: pointer;
  height: 100px;
  width: 100px;
  position: absolute;
  display: block;
  z-index: 5;
  transform: scale(1);
}

.map {
  background-position-x: -357px;
  height: 120px;
  width: 225px;
  position: absolute;
  display: none;
}

/* background-position-x: this changes the icon from grey to green */

.depression.info_icon {
  background-position-x: -120px;
  background-position-y: -1590px;
  left: 8%;
  top: 20%;
  overflow: hidden;
}

.depression.map {
  background-position-y: -1660px;
  width: 226px;
  height: 154px;
  left: 18%;
  top: 8%;
  overflow: hidden;
}

.nausea.info_icon {
  background-position-x: -120px;
  background-position-y: -1710px;
  left: 8%;
  top: 58%;
  overflow: hidden;
}

.nausea.map {
  background-position-y: -1834px;
  width: 226px;
  height: 188px;
  left: 18%;
  top: 40%;
  overflow: hidden;
}

.breast-tenderness.info_icon {
  background-position-x: -120px;
  background-position-y: -1830px;
  left: 75%;
  top: 20%;
  overflow: hidden;
}

.breast-tenderness.map {
  background-position-y: -2042px;
  height: 6px;
  left: 48%;
  top: 27%;
  overflow: hidden;
}

.weight-gain.info_icon {
  background-position-x: -120px;
  background-position-y: -1950px;
  left: 75%;
  top: 61%;
  overflow: hidden;
}

.weight-gain.map {
  background-position-y: -2068px;
  height: 222px;
  left: 48%;
  top: 40%;
  overflow: hidden;
}

.active_info_icon {
  background-position-x: -240px !important;
}

.active_map {
  display: block !important;
}

.floating_description,
#your_questions_explanatory_box {
  background-color: #669900;
  border: 0 none;
  color: white;
  display: none;
  font-size: 11px;
  font-style: normal;
  line-height: 19px;
  position: absolute;
  text-align: left;
  width: 250px;
}

.floating_description .head,
#your_questions_explanatory_box .head {
  padding: 0px 10px 0px 10px;
  border-bottom: 1px dotted white;
  height: 32px;
}

.floating_description .head h1,
#your_questions_explanatory_box .head h1 {
  font-family: 'Georgia', Georgia, Serif;
  line-height: 0px;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
}

.floating_description .desc_close,
#your_questions_explanatory_box .desc_close {
  position: relative;
  display: inline-block;
  left: 216px;
  top: -23px;
  height: 15px;
  width: 16px;
  cursor: pointer;
  background: no-repeat url(../assets/app2/x-icon.png);
}

.floating_description .body,
#your_questions_explanatory_box .body {
  padding: 6px 10px 10px 10px;
}

.floating_description .desc_arrow,
#your_questions_explanatory_box .desc_arrow {
  border-bottom: 12px solid #669900;
  border-top: 0px solid #669900;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  font-size: 0px;
  left: 137px;
  line-height: 0%;
  position: absolute;
  top: -12px;
  width: 0px;
}

.floating_description.depression {
  left: 48%;
  top: 15%;
}

.floating_description.depression .desc_arrow {
  left: 10px;
}

.floating_description.nausea {
  left: 32%;
  top: 22%;
}

.floating_description.nausea .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.breast-tenderness {
  left: 30%;
  top: 33%;
}

.floating_description.breast-tenderness .desc_arrow {
  left: 119px;
}

.floating_description.weight-gain {
  left: 29%;
  top: 22%;
}

.floating_description.weight-gain .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

@media only screen and (max-width: 600px) {
  .display {
    transform: scale(0.5);
  }

  .floating_description {
    transform: scale(1.5);
  }

  .floating_description .desc_arrow,
  #your_questions_explanatory_box .desc_arrow {
    display: none;
  }
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
