import { render, staticRenderFns } from "./AppFamilyPlanningThatGuysUse.vue?vue&type=template&id=2149980a&scoped=true&"
import script from "./AppFamilyPlanningThatGuysUse.vue?vue&type=script&lang=js&"
export * from "./AppFamilyPlanningThatGuysUse.vue?vue&type=script&lang=js&"
import style0 from "./AppFamilyPlanningThatGuysUse.vue?vue&type=style&index=0&id=2149980a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2149980a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
