<template>
  <v-container fill-height fluid class="howtowomen">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'howtowomen-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="5" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h5">
                HOW DO I USE IT?
              </v-card-title>
              <v-card-text class="white--text">
                Click to see how each method is used and think about your lifestyle, your body, and
                how much you want to deal with your method. (Some require more planning and
                preparation than others.)
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="7" sm="12">
              <app-how-do-i-use-it />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppHowDoIUseIt from '../components/AppHowDoIUseIt.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  components: { Rainbow, AppHowDoIUseIt },
  name: 'Home',
  methods: {
    navigateNext() {
      this.$router.push('/how_to_men');
    },
    navigatePrev() {
      this.$router.push('/IUDInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.howtowomen {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
