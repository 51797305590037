import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import ReasonsWhy from '../views/ReasonsWhy.vue';
import LetsGetStarted from '../views/LetsGetStarted.vue';
import WhatAreMyOptions from '../views/WhatAreMyOptions.vue';
import PrepAndPepWhatsTheDifference from '../views/PrepAndPepWhatsTheDifference.vue';
import LetsTalkAboutPrepMenu from '../views/LetsTalkAboutPrepMenu.vue';
import MethodChoice from '../views/MethodChoice.vue';
import LearnMore from '../views/LearnMore.vue';
import App1 from '../views/App1.vue';
import FamilyPlanningBigDeal from '../views/FamilyPlanningBigDeal.vue';
import Effectiveness from '../views/Effectiveness.vue';
import IUDInfo from '../views/IudInfo.vue';
import HowToWomen from '../views/HowToWomen.vue';
import HowToMen from '../views/HowToMen.vue';
import EmergContraception from '../views/EmergContraception.vue';
import HowOften from '../views/HowOften.vue';
import SideEffectsInfo from '../views/SideEffectsInfo.vue';
import SideEffectsMain from '../views/SideEffectsMain.vue';
import SideEffectsContra from '../views/SideEffectsContra.vue';
import FertilityFuture from '../views/FertilityFuture.vue';
import Finish from '../views/Finish.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Page1',
    name: 'Page1',
    component: Home,
  },
  {
    path: '/ReasonsWhy',
    name: 'ReasonsWhy',
    component: ReasonsWhy,
  },
  {
    path: '/LetsGetStarted',
    name: 'LetsGetStarted',
    component: LetsGetStarted,
  },
  {
    path: '/WhatAreMyOptions',
    name: 'WhatAreMyOptions',
    component: WhatAreMyOptions,
  },
  {
    path: '/PrepAndPepWhatsTheDifference',
    name: 'PrepAndPepWhatsTheDifference',
    component: PrepAndPepWhatsTheDifference,
  },
  {
    path: '/LetsTalkAboutPrepMenu',
    name: 'LetsTalkAboutPrepMenu',
    component: LetsTalkAboutPrepMenu,
  },
  {
    path: '/MethodChoice',
    name: 'MethodChoice',
    component: MethodChoice,
  },
  {
    path: '/LearnMore',
    name: 'LearnMore',
    component: LearnMore,
  },
  {
    path: '/FamilyPlanningBigDeal',
    name: 'FamilyPlanningBigDeal',
    component: FamilyPlanningBigDeal,
  },
  {
    path: '/effectiveness',
    name: 'effectiveness',
    component: Effectiveness,
  },
  {
    path: '/IUDInfo',
    name: 'IUDInfo',
    component: IUDInfo,
  },
  {
    path: '/how_to_women',
    name: 'How To Women',
    component: HowToWomen,
  },
  {
    path: '/how_to_men',
    name: 'How To Men',
    component: HowToMen,
  },
  {
    path: '/emergency_contraception',
    name: 'Emergency Contraception',
    component: EmergContraception,
  },
  {
    path: '/how_often',
    name: 'How Often',
    component: HowOften,
  },
  {
    path: '/SideEffectsInfo',
    name: 'SideEffectsInfo',
    component: SideEffectsInfo,
  },
  {
    path: '/SideEffectsMain',
    name: 'SideEffectsMain',
    component: SideEffectsMain,
  },
  {
    path: '/side_effects_contra',
    name: 'Side Effects Contra',
    component: SideEffectsContra,
  },
  {
    path: '/fertility_future',
    name: 'Fertility Future',
    component: FertilityFuture,
  },
  {
    path: '/Finish',
    name: 'Finish',
    component: Finish,
  },
  {
    path: '/App1',
    name: 'App1',
    component: App1,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
