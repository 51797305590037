<template>
  <v-container fill-height fluid class="fertilityfuture">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'fertilityfuture-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                WHAT IF I DECIDE I WANT TO GET PREGNANT?
              </v-card-title>
              <v-card-text class="white--text">
                <v-row>
                  <v-col lg="6" md="12" sm="12" cols="12">
                    No family planning method will stop you from being able to get pregnant in the
                    future. (Except female sterilization. Remember that one's permanent.)
                    <br />
                    <br />
                    Keep in mind that it may take several months or longer to get pregnant after
                    using Depo.
                    <br />
                    <br />
                    And because the IUDC and implant are longer-term methods, we generally recommend
                    them for women who do not want to get pregnant for at least a year. (But you can
                    have them removed and stop using them at any time.)
                  </v-col>
                  <v-col lg="4" offset-lg="1" md="12" sm="12" cols="12">
                    <v-img class="center" max-height="500px" src="../assets/fertility-future.svg" />
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'FertilityFutureInfo',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/Finish');
    },
    navigatePrev() {
      this.$router.push('/side_effects_contra');
    },
  },
};
</script>

<style lang="scss" scoped>
.fertilityfuture {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
