<template>
  <!-- <ul class="rainbow">
    <li class="dark_gray_blue"></li>
    <li class="light_blue"></li>
    <li class="light_green"></li>
    <li class="orange"></li>
    <li class="dark_orange"></li>
  </ul> -->
  <hr />
</template>

<script>
export default {
  name: 'Rainbow',
  data: () => ({}),
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
hr
  margin-top: 15px
  margin-left: 0px
  height: 3px
  background-image: linear-gradient(270deg, #a255d5, #004d5d, #83cfdd, #a2c800, #f37928, )
  max-width: 100%
  display: flex
  justify-content: center
  border: none
</style>
