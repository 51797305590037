<template>
  <v-card flat>
    <div class="wrapper">
      <div class="display">
        <div
          @click="setActive(0)"
          :class="['depression', 'info_icon', activeMethodIndex === 0 ? 'active_info_icon' : '']"
          data-name="Depression"
          data-label="Depression"
          data-description="Some women who use Depo or the implant may feel sad, but most women feel fine on those methods."
        ></div>
        <div :class="['depression', 'map', activeMethodIndex === 0 ? 'active_map' : '']"></div>
        <div
          @click="setActive(1)"
          :class="[
            'decreased-interest-in-sex',
            'info_icon',
            activeMethodIndex === 1 ? 'active_info_icon' : '',
          ]"
          data-name="Decreased Interest in Sex"
          data-label="Decreased Interest in Sex"
          data-description="Some women who use Depo or the implant may experience a decreased sex drive, but most women don't report any change."
        ></div>
        <div
          :class="['decreased-interest-in-sex', 'map', activeMethodIndex === 1 ? 'active_map' : '']"
        ></div>
        <div
          @click="setActive(2)"
          :class="['cramping', 'info_icon', activeMethodIndex === 2 ? 'active_info_icon' : '']"
          data-name="Cramping"
          data-label="Cramping"
          data-description="The IUDC can make your periods more uncomfortable."
        ></div>
        <div :class="['cramping', 'map', activeMethodIndex === 2 ? 'active_map' : '']"></div>
        <div
          @click="setActive(3)"
          :class="['weight-gain', 'info_icon', activeMethodIndex === 3 ? 'active_info_icon' : '']"
          data-name="Weight Gain"
          data-label="Weight Gain"
          data-description="Depo may cause some weight gain. This is generally no more than 5-10 pounds."
        ></div>
        <div :class="['weight-gain', 'map', activeMethodIndex === 3 ? 'active_map' : '']"></div>
        <div
          @click="setActive(4)"
          :class="[
            'lack-of-bleeding',
            'info_icon',
            activeMethodIndex === 4 ? 'active_info_icon' : '',
          ]"
          data-name="Lack of Bleeding"
          data-label="Lack of Bleeding"
          data-description="Some methods (like Depo) can make your period go away completely. While many women think this is great, others aren't so sure. If you're worried about the effect on your health, don't be. It is completely safe to not have a period on these methods."
        ></div>
        <div
          :class="['lack-of-bleeding', 'map', activeMethodIndex === 4 ? 'active_map' : '']"
        ></div>
        <div
          @click="setActive(5)"
          :class="[
            'irregular-bleeding-spotting',
            'info_icon',
            activeMethodIndex === 5 ? 'active_info_icon' : '',
          ]"
          data-name="Irregular Bleeding/Spotting"
          data-label="Irregular Bleeding/Spotting"
          data-description="Having light bleeding throughout the month can happen with Depo and the implant. For Depo, this usually gets better after 6 months. The spotting is not dangerous, but it can be annoying. Some women also get light spotting between periods in the first 2-3 months of using the pill."
        ></div>
        <div
          :class="[
            'irregular-bleeding-spotting',
            'map',
            activeMethodIndex === 5 ? 'active_map' : '',
          ]"
        ></div>
        <div
          @click="setActive(6)"
          :class="[
            'heavy-bleeding',
            'info_icon',
            activeMethodIndex === 6 ? 'active_info_icon' : '',
          ]"
          data-name="Heavy Bleeding"
          data-label="Heavy Bleeding"
          data-description="The IUDC can make you have heavier periods."
        ></div>
        <div :class="['heavy-bleeding', 'map', activeMethodIndex === 6 ? 'active_map' : '']"></div>
        <div
          v-if="activeMethod"
          :class="['floating_description ', activeMethod.class]"
          style="z-index: 6; display: block"
        >
          <div class="head">
            <h1>{{ activeMethod.method }}</h1>
            <div class="desc_close" @click="clear"></div>
          </div>
          <div class="body">
            {{ activeMethod.text }}
          </div>
          <div class="desc_arrow"></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppSideEffectsAnnoyingStuff',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'Depression',
        text: 'Some women who use Depo or the implant may feel sad, but most women feel fine on those methods.',
        class: 'depression',
      },
      {
        method: 'Decreased Interest in Sex',
        text: "Some women who use Depo or the implant may experience a decreased sex drive, but most women don't report any change.",
        class: 'decreased-interest-in-sex',
      },
      {
        method: 'Cramping',
        text: 'The IUDC can make your periods more uncomfortable.',
        class: 'cramping',
      },
      {
        method: 'Weight Gain',
        text: 'Depo may cause some weight gain. This is generally no more than 5-10 pounds.',
        class: 'weight-gain',
      },
      {
        method: 'Lack of Bleeding',
        text: "Some methods (like Depo) can make your period go away completely. While many women think this is great, others aren't so sure. If you're worried about the effect on your health, don't be. It is completely safe to not have a period on these methods.",
        class: 'lack-of-bleeding',
      },
      {
        method: 'Irregular Bleeding/Spotting',
        text: 'Having light bleeding throughout the month can happen with Depo and the implant. For Depo, this usually gets better after 6 months. The spotting is not dangerous, but it can be annoying. Some women also get light spotting between periods in the first 2-3 months of using the pill.',
        class: 'irregular-bleeding-spotting',
      },
      {
        method: 'Heavy Bleeding',
        text: 'The IUDC can make you have heavier periods.',
        class: 'heavy-bleeding',
      },
    ],
    activeMethod: '',
    activeMethodIndex: '',
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
    clear() {
      this.activeMethod = '';
      this.activeMethodIndex = '';
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

/* relative needs to be set here to ensure everything lines up foir different screen sizes */
.display {
  background: no-repeat url('../assets/app2/silhouette-female.png') center;
  background-size: 202px 733px;
  height: 794px;
  margin-top: -28px;
  width: 677px;
  min-width: 677px;
  max-width: 677px;
  position: relative;
  overflow: hidden;
}

.info_icon,
.map {
  background-image: url('../assets/app5/sprite-side-effects.png');
  background-size: 750px 2310px;
}

.info_icon {
  cursor: pointer;
  height: 100px;
  width: 100px;
  position: absolute;
  display: block;
  z-index: 5;
  transform: scale(1);
}

.map {
  background-position-x: -357px;
  height: 120px;
  width: 225px;
  position: absolute;
  display: none;
}

/* background-position-x: this changes the icon from grey to green */

.depression.info_icon {
  background-position-x: -120px;
  background-position-y: -700px;
  left: 8%;
  top: 10%;
  overflow: hidden;
}

.depression.map {
  background-position-y: -575px;
  height: 75px;
  left: 18%;
  top: 8%;
  overflow: hidden;
}

.decreased-interest-in-sex.info_icon {
  background-position-x: -120px;
  background-position-y: -820px;
  left: 8%;
  top: 28%;
  overflow: hidden;
}

.decreased-interest-in-sex.map {
  background-position-y: -650px;
  height: 250px;
  left: 18%;
  top: 5%;
  overflow: hidden;
}

.cramping.info_icon {
  background-position-x: -120px;
  background-position-y: -940px;
  left: 8%;
  top: 48%;
  overflow: hidden;
}

.cramping.map {
  background-position-y: -903px;
  height: 50px;
  left: 18%;
  top: 47%;
  overflow: hidden;
}

.weight-gain.info_icon {
  background-position-x: -120px;
  background-position-y: -1060px;
  left: 8%;
  top: 70%;
  overflow: hidden;
}

.weight-gain.map {
  background-position-y: -972px;
  width: 248px;
  height: 283px;
  left: 18%;
  top: 40%;
  overflow: hidden;
}

.lack-of-bleeding.info_icon {
  background-position-x: -120px;
  background-position-y: -410px;
  left: 75%;
  top: 20%;
  overflow: hidden;
}

.lack-of-bleeding.map {
  background-position-y: -379px;
  width: 229px;
  left: 50%;
  top: 30%;
  overflow: hidden;
}

.irregular-bleeding-spotting.info_icon {
  background-position-x: -120px;
  background-position-y: -1300px;
  left: 75%;
  top: 42%;
  overflow: hidden;
}

.irregular-bleeding-spotting.map {
  background-position-y: -1468px;
  height: 33px;
  left: 48%;
  top: 48%;
  overflow: hidden;
}

.heavy-bleeding.info_icon {
  background-position-x: -240px;
  background-position-y: -1420px;
  left: 75%;
  top: 61%;
  overflow: hidden;
}

.heavy-bleeding.map {
  background-position-y: -1521px;
  left: 48%;
  top: 52%;
  overflow: hidden;
}

.active_info_icon {
  background-position-x: -240px !important;
}

.active_map {
  display: block !important;
}

.floating_description,
#your_questions_explanatory_box {
  background-color: #669900;
  border: 0 none;
  color: white;
  display: none;
  font-size: 11px;
  font-style: normal;
  line-height: 19px;
  position: absolute;
  text-align: left;
  width: 250px;
}

.floating_description .head,
#your_questions_explanatory_box .head {
  padding: 0px 10px 0px 10px;
  border-bottom: 1px dotted white;
  height: 32px;
}

.floating_description .head h1,
#your_questions_explanatory_box .head h1 {
  font-family: 'Georgia', Georgia, Serif;
  line-height: 0px;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
}

.floating_description .desc_close,
#your_questions_explanatory_box .desc_close {
  position: relative;
  display: inline-block;
  left: 216px;
  top: -23px;
  height: 15px;
  width: 16px;
  cursor: pointer;
  background: no-repeat url(../assets/app2/x-icon.png);
}

.floating_description .body,
#your_questions_explanatory_box .body {
  padding: 6px 10px 10px 10px;
}

.floating_description .desc_arrow,
#your_questions_explanatory_box .desc_arrow {
  border-bottom: 12px solid #669900;
  border-top: 0px solid #669900;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  font-size: 0px;
  left: 137px;
  line-height: 0%;
  position: absolute;
  top: -12px;
  width: 0px;
}

.floating_description.depression {
  left: 48%;
  top: 15%;
}

.floating_description.depression .desc_arrow {
  left: 10px;
}

.floating_description.decreased-interest-in-sex {
  left: 30%;
  top: 38%;
}

.floating_description.decreased-interest-in-sex .desc_arrow {
  left: 10px;
}

.floating_description.cramping {
  left: 30%;
  top: 33%;
}

.floating_description.cramping .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.weight-gain {
  left: 32%;
  top: 25%;
}

.floating_description.weight-gain .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.lack-of-bleeding {
  left: 22%;
  top: 48%;
}

.floating_description.lack-of-bleeding .desc_arrow {
  left: 187px;
}

.floating_description.irregular-bleeding-spotting {
  left: 30%;
  top: 20%;
}

.floating_description.irregular-bleeding-spotting .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

.floating_description.heavy-bleeding {
  left: 29%;
  top: 38%;
}

.floating_description.heavy-bleeding .desc_arrow {
  border-top-width: 12px;
  border-bottom-width: 0px;
  bottom: -12px;
  top: initial;
  left: 119px;
}

@media only screen and (max-width: 600px) {
  .display {
    transform: scale(0.5);
  }

  .floating_description {
    transform: scale(1.5);
  }

  .floating_description .desc_arrow,
  #your_questions_explanatory_box .desc_arrow {
    display: none;
  }
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
