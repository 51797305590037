<template>
  <v-container fill-height fluid class="howtomen">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'howtomen-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="5" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h5 text-lg-h5">
                FAMILY PLANNING THAT GUYS USE
              </v-card-title>
              <v-card-text class="white--text">
                As you think about family planning, it can be helpful to talk with your sexual
                partner about your options and preferences. After all, there are a few methods that
                men can use too.
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="7" sm="12">
              <app-family-planning-that-guys-use />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppFamilyPlanningThatGuysUse from '../components/AppFamilyPlanningThatGuysUse.vue';
import Rainbow from '../components/Rainbow.vue';
export default {
  components: { Rainbow, AppFamilyPlanningThatGuysUse },
  name: 'Home',
  methods: {
    navigateNext() {
      this.$router.push('/emergency_contraception');
    },
    navigatePrev() {
      this.$router.push('/how_to_women');
    },
  },
};
</script>

<style lang="scss" scoped>
.howtomen {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
