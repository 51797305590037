<template>
  <v-container fill-height fluid class="home">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 d-flex align-center colored-card ',
            $vuetify.breakpoint.lgAndUp ? 'home-card-background' : '',
          ]"
          elevation="20"
          style="min-height: 80vh"
        >
          <v-row>
            <v-col lg="5" offset-lg="7" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h2 text-lg-h3">
                IS PrEP FOR ME?
              </v-card-title>
              <v-card-title class="white--text text-xl-h5 text-lg-h6">
                BEFORE WE GET STARTED
              </v-card-title>
              <v-card-text class="text">
                Choices about HIV prevention are big decisions about taking care of yourself.
                <br /><br />
                You have choices, and the right choice is different for different women. Your
                choices may change over time because things in your life change, or because what
                matters to you change.<br /><br />
                In just a few minutes, we want to share with you information about all your options,
                so you can find methods that are right for you.

                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Home',
  components: {
    Rainbow,
  },
  async created() {
    console.log(this.$route.query);
    if (this.$route.query && this.$route.query.skipfamilyplanning === 'true') {
      await this.$store.dispatch('setSkipFamilyPlanning', true);
    } else {
      await this.$store.dispatch('setSkipFamilyPlanning', false);
    }
    console.log('skip family planning: ' + this.skipfamilyplanning);
  },
  computed: {
    ...mapGetters(['skipfamilyplanning']),
  },
  methods: {
    ...mapActions(['setSkipFamilyPlanning']),
    navigateNext() {
      this.$router.push('/ReasonsWhy');
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
.home-card-background {
  background-image: url('../assets/home.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
