<template>
  <v-container fill-height fluid class="sideffectsinfo">
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card',
            $vuetify.breakpoint.lgAndUp ? 'sideffectsinfo-card-background' : '',
          ]"
          elevation="20"
        >
          <v-row>
            <v-col lg="12" offset-lg="0" md="12" sm="12" cols="12">
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                ARE THERE ANY SIDE EFFECTS?
              </v-card-title>
              <v-card-text class="white--text">
                <v-row>
                  <v-col lg="6" md="12" sm="12" cols="12">
                    We know that sometimes contraception has side effects and you might have
                    concerns about that. Maybe your friends had a bad experience with one or you’ve
                    heard rumors about some methods.
                    <br />
                    <br />
                    The reality is every method has some positive and negative things to be aware of
                    but since everyone's different you may not experience any side effects at all.
                    Take a look at our list of side effects and the methods they’re associated with.
                    We’ve also included the good things that can happen with each method.
                    <br />
                    <br />
                    We want you to have all the information necessary to make a good decision. And
                    if you have any lingering questions about side effects make sure to ask your
                    provider about them.
                    <br />
                    <br />
                    Here's the deal with emergency contraception: Always think of it as plan B.
                  </v-col>
                  <v-col lg="4" offset-lg="1" md="12" sm="12" cols="12">
                    <v-img
                      class="center"
                      max-height="500px"
                      src="../assets/side-effects-graphic.png"
                    />
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row>
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rainbow from '../components/Rainbow.vue';
export default {
  name: 'SideEffectsInfo',
  components: {
    Rainbow,
  },
  methods: {
    navigateNext() {
      this.$router.push('/SideEffectsMain');
    },
    navigatePrev() {
      this.$router.push('/how_often');
    },
  },
};
</script>

<style lang="scss" scoped>
.sideffectsinfo {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}
</style>
