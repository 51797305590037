<template>
  <v-container fill-height fluid class="letstalkaboutprepmenu">
    <v-row>
      <v-col cols="12" lg="12" offset-lg="0" md="12" sm="12">
        <v-card
          :class="[
            'pa-2 align-center colored-card ',
            $vuetify.breakpoint.lgAndUp && (step === 6 || step === 4 || step === 5)
              ? ''
              : 'letstalkaboutprepmenu-card-background',
          ]"
          elevation="20"
          style="min-height: 80vh"
        >
          <v-row>
            <v-col
              :lg="step === 6 || step === 5 || step === 4 ? '12' : '7'"
              :offset-lg="step === 6 || step === 5 || step === 4 ? '0' : '5'"
              md="12"
              sm="12"
              cols="12"
            >
              <v-card-title class="orange--text text-xl-h4 text-lg-h5">
                {{ pageTitle }}
              </v-card-title>
              <v-card-text class="white--text" v-if="step == 0">
                We want to make sure you know about all your options, but today, we’ll focus on
                PrEP, because it's new, extremely safe and highly effective. Plus, it's private, and
                you can decide who to tell about it. Here are some things you might want to know
                about it.
                <br />
                <br />
                Click on each item below to learn more:

                <v-card
                  @click="
                    pageTitle = 'WHAT IS PrEP?';
                    step1completed = true;
                    step = 1;
                  "
                  color="#828282"
                  style="
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  :class="[
                    'd-flex mt-2 pa-3 white--text text-h5',
                    step1completed ? 'gradient' : '',
                  ]"
                >
                  What is PrEP?
                  <v-spacer></v-spacer>
                  <v-icon color="white">{{
                    step1completed ? 'mdi-check' : 'mdi-chevron-right'
                  }}</v-icon>
                </v-card>
                <v-card
                  @click="
                    pageTitle = 'WHAT ARE THE DIFFERENT TYPES OF PrEP?';
                    step2completed = true;
                    step = 2;
                  "
                  color="#828282"
                  style="
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  :class="[
                    'd-flex mt-2 pa-3 white--text text-h5',
                    step2completed ? 'gradient' : '',
                  ]"
                >
                  What are the different types of PrEP?
                  <v-spacer></v-spacer>
                  <v-icon color="white">{{
                    step2completed ? 'mdi-check' : 'mdi-chevron-right'
                  }}</v-icon>
                </v-card>
                <v-card
                  @click="
                    pageTitle = 'HOW WELL DOES PrEP PREVENT HIV?';
                    step3completed = true;
                    step = 3;
                  "
                  color="#828282"
                  style="
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  :class="[
                    'd-flex mt-2 pa-3 white--text text-h5',
                    step3completed ? 'gradient' : '',
                  ]"
                >
                  How well does PrEP prevent HIV?
                  <v-spacer></v-spacer>
                  <v-icon color="white">{{
                    step3completed ? 'mdi-check' : 'mdi-chevron-right'
                  }}</v-icon>
                </v-card>
                <v-card
                  @click="
                    pageTitle = 'HOW DO I TAKE PrEP?';
                    step4completed = true;
                    step = 4;
                  "
                  color="#828282"
                  style="
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  :class="[
                    'd-flex mt-2 pa-3 white--text text-h5',
                    step4completed ? 'gradient' : '',
                  ]"
                >
                  How do I take PrEP?
                  <v-spacer></v-spacer>
                  <v-icon color="white">{{
                    step4completed ? 'mdi-check' : 'mdi-chevron-right'
                  }}</v-icon>
                </v-card>
                <v-card
                  @click="
                    pageTitle = 'WHAT DO I NEED TO KNOW ABOUT PrEP?';
                    step5completed = true;
                    step = 5;
                  "
                  color="#828282"
                  style="
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  :class="[
                    'd-flex mt-2 pa-3 white--text text-h5',
                    step5completed ? 'gradient' : '',
                  ]"
                >
                  What do I need to know about PrEP (the good stuff and side-effects)?
                  <v-spacer></v-spacer>
                  <v-icon color="white">{{
                    step5completed ? 'mdi-check' : 'mdi-chevron-right'
                  }}</v-icon>
                </v-card>
                <v-card
                  @click="
                    pageTitle = 'WHAT\'S TRUE AND FALSE ABOUT PrEP?';
                    step6completed = true;
                    step = 6;
                  "
                  color="#828282"
                  style="
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  :class="[
                    'd-flex mt-2 pa-3 white--text text-h5',
                    step6completed ? 'gradient' : '',
                  ]"
                >
                  What's true and false about PrEP?
                  <v-spacer></v-spacer>
                  <v-icon color="white">{{
                    step6completed ? 'mdi-check' : 'mdi-chevron-right'
                  }}</v-icon>
                </v-card>
                <rainbow />
              </v-card-text>
              <v-card-text class="white--text" v-if="step == 1">
                <v-card color="pa-5 ">
                  <ul>
                    <li>PrEP is a medicine HIV-negative people take to prevent getting HIV.</li>
                    <v-card color="transparent" class="my-5" flat>
                      <span class="blue--text text-lg-h4">Pr</span
                      ><span class="grey--text text-lg-h4">e = before </span><br />
                      <span class="blue--text text-lg-h4">E</span
                      ><span class="grey--text text-lg-h4">xposure = contracting HIV</span><br />
                      <span class="blue--text text-lg-h4">P</span>
                      <span class="grey--text text-lg-h4">rophylaxis = prevent infection </span>
                    </v-card>
                    <li>PrEP works best if taken regularly.</li>
                    <li>
                      PrEP is a private method that you control (you don’t have to tell anyone about
                      it if you don’t want to!).
                    </li>
                    <li>Both men and women can take PrEP.</li>
                    <li>You are in control of starting and stopping PrEP.</li>
                  </ul>
                </v-card>
                <rainbow />
              </v-card-text>
              <v-card-text class="white--text" v-if="step == 2">
                <v-card color="pa-5">
                  The PrEP pill (taken by mouth) is available now.
                  <br />
                  <br />
                  The PrEP injection and PrEP ring (goes in the vagina) will be available soon, so
                  we wanted to let you know about them for the future. Many people who are
                  interested in the PrEP injection or ring chose PrEP pills for now.
                  <br />
                  <br />
                  Check with your local health department to find out when the PrEP injection or
                  ring will be available for you.
                  <br />
                  <br />
                  <v-row>
                    <v-col
                      v-for="(card, index) in whatarethedifferenttypesofPrEPcards"
                      cols="12"
                      lg="4"
                      md="12"
                      sm="12"
                      :key="index"
                      class="d-flex"
                      style="flex-direction: column"
                    >
                      <hover-card :heading="card.heading" :text="card.text" :image="card.image" />
                    </v-col>
                  </v-row>
                </v-card>
                <rainbow />
              </v-card-text>
              <v-card-text class="white--text" v-if="step == 3">
                <v-card color="pa-5 ">
                  All types of PrEP lower your chances of getting HIV. Some types of PrEP lower your
                  chance of getting HIV more, but they
                  <span class="font-weight-bold text-decoration-underline">all</span> only work if
                  you take them
                  <span class="font-weight-bold text-decoration-underline">correctly</span>! That’s
                  why we want you to have all the information you need to choose a type that works
                  for you, and you will take. What matters most is the type of PrEP that is best for
                  YOU.
                  <br />
                  <br />
                  People choose to use PrEP for many different reasons. How well each type of PrEP
                  works is one of many ways people decide what PrEP is right for them.

                  <br />
                  <br />
                  <v-row>
                    <v-col
                      v-for="(card, index) in howwelldoestPreppreventHIVcards"
                      cols="12"
                      lg="4"
                      md="12"
                      sm="12"
                      :key="index"
                      class="d-flex"
                      style="flex-direction: column"
                    >
                      <text-card
                        :heading="card.heading"
                        :text="card.text"
                        :image="card.image"
                        :text-image="card.textImage"
                        :star-rating="card.starRating"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <rainbow />
              </v-card-text>
              <v-card-text class="white--text" v-if="step == 4">
                <v-row>
                  <v-col
                    v-for="(card, index) in howdoitakeitcards"
                    cols="12"
                    lg="4"
                    md="12"
                    sm="12"
                    :key="index"
                    class="d-flex"
                    style="flex-direction: column"
                  >
                    <four-point-card
                      :heading="card.heading"
                      :point1="card.point1"
                      :point2="card.point2"
                      :point3="card.point3"
                      :point4="card.point4"
                      :image="card.image"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert dark class="warning text-center text-h6"
                      >All forms of PrEP
                      <span class="font-weight-bold text-decoration-underline">do not</span> prevent
                      STIs or pregnancy. Don’t forget to use a condom to stay healthy and use family
                      planning if you don’t want to get pregnant!</v-alert
                    >
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-text class="white--text" v-if="step == 5">
                <v-tabs
                  v-model="knowabout"
                  slider-color="indigo"
                  show-arrows
                  style="
                    border-top-left-radius: 20px !important;
                    border-top-right-radius: 20px !important;
                  "
                >
                  <v-tab :key="'good'" :href="'#good'"> Good Stuff </v-tab>
                  <v-tab :key="'annoying'" :href="'#annoying'"> Annoying Stuff </v-tab>
                  <v-tab :key="'noworries'" :href="'#noworries'"> Stuff Not To Worry About </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="knowabout"
                  style="
                    border-bottom-left-radius: 20px !important;
                    border-bottom-right-radius: 20px !important;
                  "
                >
                  <v-tab-item :key="'good'" :value="'good'">
                    <v-card>
                      <v-card-text class="black--text">
                        <ul>
                          <li>Very safe.</li>
                          <li>Keeps you healthy by preventing HIV.</li>
                          <li>Private method that you control.</li>
                          <li>Increases confidence and decreases fear of getting HIV.</li>
                          <li>Safe with all types of family planning.</li>
                          <li>Safe to use while pregnant and breastfeeding.</li>
                        </ul>
                        <br />
                        <v-row>
                          <v-col
                            v-for="(card, index) in knowaboutgoodcards"
                            cols="12"
                            lg="4"
                            md="12"
                            sm="12"
                            :key="index"
                          >
                            <text-card
                              :heading="card.heading"
                              :text="card.text"
                              :image="card.image"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="'annoying'" :value="'annoying'">
                    <v-card>
                      <v-card-text class="black--text">
                        <ul>
                          <li>
                            Like most medicines, PrEP has some side effects. In general, they are
                            mild and go away quickly. Most people who use PrEP don’t have side
                            effects, or aren’t bothered by them!
                          </li>
                          <li>
                            PrEP does not protect against other sexually transmitted infections.
                            Only condoms prevent against HIV and other infections.
                          </li>
                          <!-- <li>
                            PrEP does not prevent pregnancy. PrEP is safe to take with all forms of
                            family planning.
                          </li> -->
                        </ul>
                        <br />
                        <v-row>
                          <v-col
                            v-for="(card, index) in knowaboutannoyingcards"
                            cols="12"
                            lg="4"
                            md="12"
                            sm="12"
                            :key="index"
                          >
                            <text-card
                              :heading="card.heading"
                              :text="card.text"
                              :image="card.image"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="'noworries'" :value="'noworries'">
                    <v-card>
                      <v-card-text class="black--text">
                        <ul>
                          <li>
                            You don’t have to take PrEP for your whole life! You can use PrEP for as
                            long as you need it. We suggest talking to your provider before stopping
                            PrEP.
                          </li>
                        </ul>
                        <br />
                        <v-row>
                          <v-col
                            v-for="(card, index) in knowaboutnoworriescards"
                            cols="12"
                            lg="4"
                            md="12"
                            sm="12"
                            :key="index"
                          >
                            <text-card
                              :heading="card.heading"
                              :text="card.text"
                              :image="card.image"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                <!-- <v-card color="pa-5">
                  <h5 class="indigo--text">THE GOOD STUFF</h5>
                  <ul>
                    <li>Very safe.</li>
                    <li>Keeps you healthy by preventing HIV.</li>
                    <li>Private method that you control.</li>
                    <li>Increases confidence and decreases fear of getting HIV.</li>
                    <li>Safe with all types of family planning.</li>
                    <li>Safe to use while pregnant and breastfeeding.</li>
                  </ul>
                  <h5 class="indigo--text">THE ANNOYING STUFF</h5>
                  <ul>
                    <li>
                      A few people have mild side effects like headache or nausea that go away
                      quickly (in a few weeks).
                    </li>
                    <li>
                      PrEP does not protect against other sexually transmitted infections. Only
                      condoms prevent against HIV and other infections.
                    </li>
                    <li>
                      PrEP does not prevent pregnancy. PrEP is safe to take with all forms of family
                      planning.
                    </li>
                  </ul>
                  <h5 class="indigo--text">STUFF TO NOT WORRY ABOUT</h5>
                  <ul>
                    <li>
                      You don’t have to take PrEP for your whole life! You can use PrEP for as long
                      as you need it. We suggest talking to your provider before stopping PrEP.
                    </li>
                  </ul>
                </v-card> -->
                <rainbow />
              </v-card-text>
              <v-card-text class="white--text" v-if="step == 6">
                <v-row>
                  <v-col cols="12" lg="4" md="12" sm="12">
                    <v-card>
                      <v-img
                        height="400"
                        :src="require(`../assets/prep.jpg`)"
                        aspect-ratio="16/9"
                        @click="revealTrueFalsePill = !revealTrueFalsePill"
                      >
                      </v-img>
                      <v-card-title class="orange--text text-xl-body-1 text-lg-body-1">
                        What's TRUE and FALSE about the PrEP pill
                      </v-card-title>
                      <v-card-actions class="align-bottom">
                        <v-btn text color="teal accent-4" @click="revealTrueFalsePill = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <v-card
                          v-if="revealTrueFalsePill"
                          class="transition-fast-in-fast-out v-card--reveal scroll pa-5"
                          height="40vh"
                        >
                          <true-false-card
                            v-for="(card, index) in pillcards"
                            :key="index"
                            :text1="card.text1"
                            :text2="card.text2"
                          />
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="revealTrueFalsePill = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="4" md="12" sm="12">
                    <v-card>
                      <v-img
                        height="400"
                        :src="require(`../assets/ring_coming_soon.jpg`)"
                        aspect-ratio="16/9"
                        @click="revealTrueFalseRing = !revealTrueFalseRing"
                      ></v-img>
                      <v-card-title class="orange--text text-xl-body-1 text-lg-body-1">
                        What's TRUE and FALSE about the PrEP ring
                      </v-card-title>
                      <v-card-actions class="align-bottom">
                        <v-btn text color="teal accent-4" @click="revealTrueFalseRing = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <v-card
                          v-if="revealTrueFalseRing"
                          class="transition-fast-in-fast-out v-card--reveal scroll pa-5"
                          height="40vh"
                        >
                          <true-false-card
                            v-for="(card, index) in ringcards"
                            :key="index"
                            :text1="card.text1"
                            :text2="card.text2"
                          />
                          <v-card-actions class="pt-0">
                            <v-btn text color="teal accent-4" @click="revealTrueFalseRing = false">
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="4" md="12" sm="12">
                    <v-card>
                      <v-img
                        height="400"
                        :src="require(`../assets/injection_coming_soon.jpg`)"
                        aspect-ratio="16/9"
                        @click="revealTrueFalseInjection = !revealTrueFalseInjection"
                      ></v-img>
                      <v-card-title class="orange--text text-xl-body-1 text-lg-body-1">
                        What's TRUE and FALSE about the PrEP injection
                      </v-card-title>
                      <v-card-actions class="align-bottom">
                        <v-btn text color="teal accent-4" @click="revealTrueFalseInjection = true">
                          Learn More
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <v-card
                          v-if="revealTrueFalseInjection"
                          class="transition-fast-in-fast-out v-card--reveal scroll pa-5"
                          height="40vh"
                        >
                          <true-false-card
                            v-for="(card, index) in injectioncards"
                            :key="index"
                            :text1="card.text1"
                            :text2="card.text2"
                          />
                          <v-card-actions class="pt-0">
                            <v-btn
                              text
                              color="teal accent-4"
                              @click="revealTrueFalseInjection = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                </v-row>
                <rainbow />
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-row v-if="step === 0">
                  <v-col>
                    <v-btn large type="previous" @click="navigatePrev"> Previous </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="navigateNext"> Compare Methods </v-btn>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="text-right">
                    <v-btn large type="next" @click="step = 0"> Next </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HoverCard from '../components/HoverCard.vue';
import TextCard from '../components/TextCard.vue';
import FourPointCard from '../components/FourPointCard.vue';
import TrueFalseCard from '../components/TrueFalseCard.vue';
import Rainbow from '../components/Rainbow.vue';

export default {
  name: 'LetsTalkAboutPrepMenu',
  components: {
    HoverCard,
    TextCard,
    FourPointCard,
    TrueFalseCard,
    Rainbow,
  },
  data: () => ({
    step: 0,
    pageTitle: "LET'S TALK ABOUT PrEP ",
    step1completed: false,
    step2completed: false,
    step3completed: false,
    step4completed: false,
    step5completed: false,
    step6completed: false,
    Show: false,
    revealTrueFalsePill: false,
    revealTrueFalseRing: false,
    revealTrueFalseInjection: false,
    whatarethedifferenttypesofPrEPcards: [
      {
        heading: 'PrEP pill: an HIV prevention pill that I can take daily',
        text: 'HIV-negative people can take an HIV prevention pill daily to prevent HIV. You can take PrEP as longs you feel you have an increased chance of getting HIV. Your situation may change and you do not have to take PrEP for life. PrEP is safe, has few side effects, and is highly effective if you take it every day.',
        image: 'prep.jpg',
      },
      {
        heading:
          'PrEP ring: a ring I can insert in my vagina like a tampon and leave in place for a month',
        text: 'HIV-negative people can use a vaginal ring every month to prevent HIV. This type of PrEP is safe and has few side effects, and is highly effective if you leave it in place. The PrEP ring is not yet available outside of research studies, but will become available soon. If you are interested in PrEP, consider using the PrEP pill (taken by mouth) until the PrEP ring is available.',
        image: 'ring_coming_soon.jpg',
      },
      {
        heading: 'PrEP injection: an injection I can take every  two months',
        text: 'HIV-negative people can get an injection every 2 months to prevent HIV. This type of PrEP is safe and has few side effects, and is highly effective. Injectable PrEP is not yet available outside of research studies, but will become available soon.  If you are interested in PrEP, consider using oral PrEP (pills by mouth) until injectable PrEP is available.',
        image: 'injection_coming_soon.jpg',
      },
    ],
    howwelldoestPreppreventHIVcards: [
      {
        heading: 'The PrEP pill (taken by mouth) works very well at preventing HIV.',
        text: 'The PrEP pill lowers your chance of getting HIV by over 90% if you take it every day.',
        image: 'prep.jpg',
        starRating: 4,
      },
      {
        heading: 'The PrEP ring (goes in the vagina) works very well at preventing HIV. ',
        text: 'The PrEP ring lowers your chance of getting HIV by over 30% and about 50% if you leave it in all month',
        image: 'ring_coming_soon.jpg',
        starRating: 3,
      },
      {
        heading: 'The PrEP injection works very well at preventing HIV.',
        text: 'The PrEP injection lowers your chance of getting HIV by over 90%, the injection seems to be more effective at preventing HIV than the pills.  ',
        image: 'injection_coming_soon.jpg',
        starRating: 5,
      },
    ],
    howdoitakeitcards: [
      {
        heading: 'How do I take the PrEP pill?',
        point1: 'The PrEP  pill is one pill that you take once a day to prevent HIV.',
        point2: 'The PrEP pill works best if you take it every day.',
        point3:
          'You have to take the PrEP pill for at least a week before you have enough medicine in your body to prevent HIV.',
        image: 'prep.jpg',
      },
      {
        heading: 'How do I use the PrEP ring?',
        point1: 'You place the PrEP ring in your vagina once a month to prevent HIV',
        point2:
          'The PrEP ring works best if you leave the ring in place without removing it for 1 month',
        point3:
          'The PrEP ring works within several hours of being placed to have enough medicine in the vagina to prevent HIV.',
        image: 'ring_coming_soon.jpg',
      },
      {
        heading: 'How do I use the PrEP injection?',
        point1: 'You receive the PrEP injection every 2 months to prevent HIV',
        point2:
          'The PrEP injection works best if you receive it regularly from your healthcare provider',
        point3: 'The PrEP injection takes up to 1 week to fully prevent HIV.',
        image: 'injection_coming_soon.jpg',
      },
    ],
    knowaboutgoodcards: [
      {
        heading: 'Other good stuff about the PrEP pill',
        text: '<ul class="text-body-1"><li>It’s available now!</li><li>Oral PrEP has been used by over a million people in the past decade and is very safe and effective</li><li>The PrEP pill is safe in pregnancy and breastfeeding</li><li>It protects you during all types of sex, and injection drug use</li><li>You control when to start or stop the PrEP pill</li><li>It is safe and effective with family planning methods</li></ul>',
        image: 'prep.jpg',
      },
      {
        heading: 'Other good stuff about the PrEP ring',
        text: '<ul class="text-body-1"><li>The PrEP ring works fast after inserting it</li><li>You don’t have to take a pill every day – you can place it and forget about it until the next month</li><li>You control when to insert and remove the ring</li><li>The ring is very safe and comfortable</li><li>You and your partner are not aware of it during sex</li><li>It is safe and effective with family planning methods</li></ul>',
        image: 'ring_coming_soon.jpg',
      },
      {
        heading: 'Other good stuff about the PrEP injection',
        text: '<ul class="text-body-1"><li>You don’t have to take a pill every day. You can take your PrEP injection and forget about it until your next injection 2 months later.</li><li>In studies, the PrEP injection was more effective at preventing HIV than the PrEP pill, probably because people don’t have to remember a pill every day.</li><li>It protects you during all types of sex</li><li>It is safe and effective with family planning methods</li></ul>',
        image: 'injection_coming_soon.jpg',
      },
    ],
    knowaboutannoyingcards: [
      {
        heading: 'The annoying stuff about the PrEP pill',
        text: '<ul class="text-body-1"><li>A few people (1 out of 10) have mild side effects like headache or nausea that go away quickly (in a few weeks)</li><li>You have to remember to take it everyday</li></ul>',
        image: 'prep.jpg',
      },
      {
        heading: 'The annoying stuff about the PrEP ring',
        text: '<ul class="text-body-1"><li>A few women have mild vaginal symptoms such as itching or slight discharge</li><li>The ring takes practice to place and get used to. Many women find it’s easy to place and use after that.</li><li>The ring only protects you only during vaginal sex (not anal sex or injection drug use)</li><li>The ring isn’t yet available outside of studies.</li></ul>',
        image: 'ring_coming_soon.jpg',
      },
      {
        heading: 'The annoying stuff about the PrEP injection',
        text: '<ul class="text-body-1"><li>Some people get side effects like a small bruise or soreness where they get their injection.</li><li>You have to see a healthcare provider to receive the injection</li><li>The PrEP injection isn’t yet available outside of studies.</li></ul>',
        image: 'injection_coming_soon.jpg',
      },
    ],
    knowaboutnoworriescards: [
      {
        heading: 'Oral PrEP',
        text: '<ul class="text-body-1"><li>You can start or continue PrEP, even if you are pregnant or breastfeeding!</li></ul>',
        image: 'prep.jpg',
      },
      {
        heading: 'Vaginal PrEP',
        text: '<ul class="text-body-1"><li>You can leave the ring in during your period, and when using a tampon</li><li>You can leave the ring in during sex. Most partners won’t feel it!</li><li>You can insert and remove the ring without going to a clinic.</li><li>The ring stays in your vagina / can’t move elsewhere in your body.</li><li>Leaving a ring in your vagina for a month will not hurt your vagina or make your vagina less clean.</li></ul>',
        image: 'ring_coming_soon.jpg',
      },
      {
        heading: 'Injectable PrEP',
        text: '<ul class="text-body-1"><li>There is no problem taking injectable PrEP with injectable family planning, or other types of family planning</li></ul>',
        image: 'injection_coming_soon.jpg',
      },
    ],
    pillcards: [
      {
        text1:
          'TRUE: Only a few PrEP users have side effects. These symptoms usually happen in the first month, are mild, last a few days and then go away.',
        text2: 'FALSE: PrEP will make you sick.',
      },
      {
        text1:
          'TRUE: It takes about a week to build up enough levels of PrEP in your body to be protected against HIV.',
        text2: 'FALSE: PrEP is like the morning after pill.',
      },
      {
        text1:
          'TRUE: If it is possible to talk to your partner about PrEP, it can build intimacy and love. If you can’t talk to your partner about PrEP, you can take it without your partner knowing and feel protected and confident.',
        text2: 'FALSE: Using PrEP means you don’t trust your partner(s).',
      },
      {
        text1:
          'TRUE: PrEP can be taken when you feel you need HIV protection, and stopped when you don’t need it.',
        text2: 'FALSE: PrEP needs to be taken forever.',
      },
      {
        text1:
          'TRUE: PrEP doesn’t protect against other STIs. Only condoms prevent against other STIs.',
        text2: 'FALSE: Using PrEP means you don’t need condoms.',
      },
      {
        text1: 'TRUE: PrEP has no effect on your ability to get pregnant.',
        text2: 'FALSE: PrEP will make you not be able to have children.',
      },
    ],
    ringcards: [
      {
        text1: 'TRUE: the ring stays in my vagina.',
        text2: 'FALSE: the ring can travel to other parts of my body and disappear.',
      },
      {
        text1: 'TRUE: The ring does not change the health of my vagina.',
        text2: 'FALSE: it is unhygienic/dirty to leave the ring in my vagina for a month.',
      },
      {
        text1: 'TRUE: the ring does not affect fertility (chances of getting pregnant).',
        text2: 'FALSE: the ring makes me infertile.',
      },
      {
        text1: 'TRUE: Most people can easily place the ring, and don’t notice it once it’s inside.',
        text2: 'FALSE: The ring is too big and uncomfortable.',
      },
      {
        text1:
          'TRUE: I can place and remove the ring myself. The ring falls out sometimes (rarely!), and I can replace it.',
        text2: 'FALSE: I have to go to clinic to place and remove the ring.',
      },
      {
        text1: 'TRUE: I can leave the ring in place during my period, and during sex.',
        text2: 'FALSE: I have to remove the ring during my period and during sex.',
      },
      {
        text1: 'TRUE: most partners won’t feel the ring.',
        text2:
          'FALSE: my partner will feel the ring during sex, and it will make him uncomfortable.',
      },
    ],
    injectioncards: [
      {
        text1: 'TRUE: the injection does not affect fertility (chances of getting pregnant).',
        text2: 'FALSE: the injection makes me infertile.',
      },
      {
        text1: 'TRUE: I can get an injection for contraception and PrEP at the same time.',
        text2: 'FALSE: I can’t get both a contraception and PrEP injection on the same day.',
      },
      {
        text1:
          'TRUE: The PrEP injection is NOT a vaccine. There is currently no vaccine to prevent HIV. The PrEP injection has medicines that block HIV, and will prevent HIV for 2 months. I can prevent HIV for longer by getting an injection every 2 months.',
        text2: 'FALSE: The PrEP injection is a vaccine.',
      },
    ],
    knowabout: 'good',
  }),
  methods: {
    navigateNext() {
      this.$router.push('/MethodChoice');
    },
    navigatePrev() {
      this.$router.push('/PrepAndPepWhatsTheDifference');
    },
  },
};
</script>

<style lang="scss" scoped>
.letstalkaboutprepmenu {
  background-image: url('../assets/background_general_grey.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}

.letstalkaboutprepmenu-card-background {
  background-image: url('../assets/prep_talk.png');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.gradient {
  background: repeating-linear-gradient(45deg, #1cd80b, #60bcb0 5px, #465298 5px, #465298 5px);
}

li {
  line-height: 1.9rem !important;
}
.text-body-1 {
  line-height: 1.9rem !important;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
