<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col class="text-center" style="background-color: #fff">
          <v-row justify="space-between" class="morelessrow">
            <v-col>
              <v-card class="pa-2" outlined tile> &lt;&lt; More Effort</v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2" outlined tile> Less Effort &gt;&gt;</v-card>
            </v-col>
          </v-row>
          <v-row class="morelessrowlowres mb-1">
            <v-col>
              <v-card class="pa-2" outlined tile>More Effort</v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" lg="12" md="12" class="wrapper">
              <div
                @click="setActive(0)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'male-condom',
                  activeMethodIndex == 0 ? 'selected_method' : '',
                ]"
              />

              <div
                @click="setActive(1)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'female-condom',
                  activeMethodIndex == 1 ? 'selected_method' : '',
                ]"
              />

              <div
                @click="setActive(2)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'pill',
                  activeMethodIndex == 2 ? 'selected_method' : '',
                ]"
              />

              <div
                @click="setActive(3)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'shot',
                  activeMethodIndex == 3 ? 'selected_method' : '',
                ]"
              />

              <div
                @click="setActive(4)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'implant',
                  activeMethodIndex == 4 ? 'selected_method' : '',
                ]"
              />

              <div
                @click="setActive(5)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'copper-iud',
                  activeMethodIndex == 5 ? 'selected_method' : '',
                ]"
              />

              <div
                @click="setActive(6)"
                :class="[
                  'ma-1',
                  'method_radio',
                  'sterilization',
                  activeMethodIndex == 6 ? 'selected_method' : '',
                ]"
              />
            </v-col>
          </v-row>
          <v-row class="morelessrowlowres mt-1">
            <v-col>
              <v-card class="pa-2" outlined tile> Less Effort</v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-card flat>
            <v-card-text>
              <h3 style="color: #006477">
                {{ activeMethod.text }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="4" md="4" sm="12">
          <v-img
            v-if="activeMethodIndex === 0"
            contain
            :src="require('../assets/app4/cal-female-condom.png')"
          />
          <v-img
            v-if="activeMethodIndex === 1"
            contain
            :src="require('../assets/app4/cal-male-condom.png')"
          />
          <v-img
            v-if="activeMethodIndex === 2"
            contain
            :src="require('../assets/app4/cal-pill.png')"
          />
          <v-img
            v-if="activeMethodIndex === 3"
            contain
            :src="require('../assets/app4/cal-shot.png')"
          />
          <v-img
            v-if="activeMethodIndex === 4"
            contain
            :src="require('../assets/app4/cal-implant.png')"
          />
          <v-img
            v-if="activeMethodIndex === 5"
            contain
            :src="require('../assets/app4/cal-copper-iud.png')"
          />
          <v-img
            v-if="activeMethodIndex === 6"
            contain
            :src="require('../assets/app4/cal-sterilization.png')"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppHowOftenDoIHaveToRememberIt',
  data: () => ({
    reveal: false,
    items: [
      {
        method: 'Internal Condom',
        chance: 21,
        text: 'Internal Condom: Use it EVERY time you have sex.',
      },
      {
        method: 'Male Condom',
        chance: 18,
        text: 'Male Condom: Use it EVERY time you have sex.',
      },
      {
        method: 'Pill',
        chance: 9,
        text: 'Pill: Take it once, everyday, at the same time.',
      },
      {
        method: 'Shot',
        chance: 6,
        text: 'Depo: Get a shot every 3 months.',
      },
      {
        method: 'Implant',
        chance: 1,
        text: "Implant: Lasts 3 years, but you can have it removed whenever you want. (Taking it out is a small procedure at the doctor's office.)",
      },
      {
        method: 'IUDC',
        chance: 1,
        text: 'IUDC: Lasts up to 12 years, but you can have it removed whenever you want. (Taking it out is easy and can be done by any health care provider.)',
      },
      {
        method: 'Sterilization',
        chance: 1,
        text: "Sterilization: This is permanent. Once you have it done, you don't have to think about it again. (But you also can't change your mind.)",
      },
    ],
    activeMethod: {
      method: 'Internal Condom',
      chance: 21,
      text: 'Internal Condom: Use it EVERY time you have sex.',
    },
    activeMethodIndex: 0,
  }),
  methods: {
    setActive(method) {
      console.log(method);
      this.activeMethod = this.items[method];
      this.activeMethodIndex = method;
    },
  },
};
</script>

<style scoped>
.egg {
  display: inline-block;
  background: no-repeat url('../assets/app1/spermegg.png') 0px center;
  height: 50px;
  width: 62px;
}

.sperm {
  background-position: -70px center;
}

.no-method {
  background: no-repeat url('../assets/app1/no-method.png') 0px center;
}

.male-condom {
  background: no-repeat url('../assets/app1/male-condom.png') 0px center;
}

.female-condom {
  background: no-repeat url('../assets/app1/female-condom.png') 0px center;
}

.pill {
  background: no-repeat url('../assets/app1/pill.png') 0px center;
}

.shot {
  background: no-repeat url('../assets/app1/shot.png') 0px center;
}

.implant {
  background: no-repeat url('../assets/app1/implant.png') 0px center;
}

.copper-iud {
  background: no-repeat url('../assets/app1/copper-iud.png') 0px center;
}

.sterilization {
  background: no-repeat url('../assets/app1/sterilization.png') 0px center;
}

.method_radio {
  display: inline-block;
  height: 60px;
  width: 80px;
  cursor: pointer;
  background-position: left center;
  background-size: cover;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.morelessrowlowres {
  display: none;
}

@media only screen and (max-width: 800px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .morelessrow {
    display: none;
  }
  .morelessrowlowres {
    display: inline-block;
  }
}

.selected_method {
  background-position-x: right;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.scroll {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
